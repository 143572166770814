<template>
<DemoBase size="xs4">
  <Checkbox title="Demo" color="primary" />
  Custom checkbox
  <Checkbox custom title="Demo" color="primary">
    <SP :class-object="['font-weight-bold']">Hello</SP>
  </Checkbox>
</DemoBase>
</template>

<script>
import DemoBase from "@/base_lib/demo/base/DemoBase"
import Checkbox from "@/base_lib/components/form/Checkbox"
import SP from "@/base_lib/components/container/SP"

export default {
  name: "CheckboxDemo",
  components: {Checkbox, DemoBase, SP}
}
</script>

<style scoped>

</style>