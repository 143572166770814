<template>
<DemoBase size="xs6">
  Default
  <Select :items="typeItems" />

  Label
  <Select :items="typeItems" label="Custom Label" />

  Label + Sorted
  <Select :items="typeItems" label="Custom Sorted Label" sorted />

  Custom
  <Select :items="typeItems" sorted custom>
    <template v-slot:item="{ item }">
      <strong>--> {{ item.label }}</strong>
    </template>
  </Select>


  Type: Team
  <Select :type="selectTeamType" :items="selectItems" :sorted="true" label="Select team" />

</DemoBase>
</template>

<script>
import DemoBase from "@/base_lib/demo/base/DemoBase"
import Select from "@/base_lib/components/form/Select"
import PageMixin from "@/base_lib/data/mixins/pageMixin"
import {teamMockData} from "@/base_lib/data/mock/teamMockData";

export default {
  name: "SelectBoxDemo",
  components: {Select, DemoBase},
  mixins: [PageMixin],
  data: () => ({
    selectItems: [],
    typeItems: [
      { value: 'fan', label: 'Fan'},
      { value: 'broker', label: 'Broker'}
    ],
  }),
  mounted() {
    this.selectItems = teamMockData
  }
}
</script>

<style scoped>

</style>