<template>
  <div v-bind:class="computedDivClass">
    <v-row>
      <v-col cols="6">
        <DatePicker
          :value="localFromDate"
          @onPicked="(date) => this.localFromDate = date"
          :min-date="internalMinDate"
          :max-date="internalEndDate"
          :placeholder="startPlaceholder"
          v-bind="$attrs">
        </DatePicker>
      </v-col>
      <v-col cols="6">
        <DatePicker
          :value="localToDate"
          @onPicked="(date) => this.localToDate = date"
          :placeholder="endPlaceholder"
          :min-date="internalToEndMinDate"
          :max-date="internalEndDate"
          :show-current="internalToDateCurrent"
          v-bind="$attrs">
        </DatePicker>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ComponentMixin from "@/base_lib/data/mixins/componentMixin"
import DatePicker from "@/base_lib/components/form/DatePicker"
import dateRangePickerProps from "@/base_lib/data/props/dateRangePicker"
import {addDays, getDateWithPlusMonths} from "@/base_lib/helper/date"

export default {
  name: "DateRangePicker",
  components: {DatePicker},
  emits: ['onPicked', 'toDateReset'],
  props: dateRangePickerProps,
  mixins: [ComponentMixin],
  data: () => ({
    localFromDate: null,
    localToDate: null,
  }),
  computed: {
    internalMinDate() {
      if (!!this.startingDate && this.$root.isDate(this.startingDate)) {
        return this.startingDate
      }
      return null
    },
    internalEndDate() {
      if (!!this.endingDate && this.$root.isDate(this.endingDate)) {
        return this.endingDate
      }
      return null
    },
    internalToEndMinDate() {
      if (this.localFromDate === null) {
        return getDateWithPlusMonths(4)
      }

      return this.localFromDate
    },
    internalToDateCurrent() {
      if (this.localFromDate !== null) {
        return addDays(this.localFromDate, 1)
      }
      return null
    },
  },
  methods: {
    validateToDate() {
      if (this.localToDate === null) return
      if (this.localFromDate > this.localToDate) {
        this.localToDate = null
        this.$emit('toDateReset')
      }
    },
  },
  created() {
    this.localFromDate = this.fromDate
    this.localToDate = this.toDate
  },
  watch: {
    localFromDate() {
      this.$emit('onPicked', this.localFromDate, this.localToDate,
        { from: new Date(this.localFromDate).getTime() / 1000,
          to: new Date(this.localToDate).getTime() / 1000,
        })
      this.validateToDate()
    },
    localToDate: {
      immediate: false,
      handler(to) {
        if (to != null) {
          this.$emit('onPicked', this.localFromDate, to,
            { from: new Date(this.localFromDate).getTime() / 1000,
              to: new Date(to).getTime() / 1000,
            })
        }
      },
    },
    fromDate: {
      immediate: false,
      handler(to) {
        this.localFromDate = to
      }
    },
    toDate: {
      immediate: false,
      handler(to, from) {
        this.localToDate = to
      }
    }
  }
}
</script>
