<template>
  <div v-bind:class="computedDivClass">
    <v-skeleton-loader :loading="loading" :type="type">
      <v-card v-bind:class="computedClass" outlined elevation="0" v-bind="$attrs">
        <slot name="header"></slot>
        <slot></slot>
      </v-card>
    </v-skeleton-loader>
  </div>
</template>

<script>
import componentMixin from "@/base_lib/data/mixins/componentMixin"
import loadingCardProps from "@/base_lib/data/props/loadingCard"

export default {
  name: "LoadingCard",
  mixins: [componentMixin],
  props: loadingCardProps,
  data: () => ({
    preDefinedClasses: ['pa-2'],
  })
}
</script>