<template>
<CenterContent :size="size">
  <slot></slot>
  <Button outlined block :class-div-object="['mt-5']" @onClick="$router.push({ name: 'demo' })" title="Return to Showcase Page"></Button>
</CenterContent>
</template>

<script>
import Button from "@/base_lib/components/form/Button"
import CenterContent from "@/base_lib/components/container/CenterContent"

export default {
  name: "DemoBase",
  components: {CenterContent, Button},
  props: {
    size: {
      type: String,
      required: false,
      default: "xs2",
    }
  },
}
</script>

<style scoped>

</style>