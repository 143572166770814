import Vue, {onBeforeMount, onBeforeUnmount} from 'vue'
import App from './App.vue'
import vuetify from "@/base_lib/plugins/vuetify"
import vuesax from '@/base_lib/plugins/vuesax'
import baseStore from "@/base_lib/store"
import createAxios from "@/base_lib/network/api"
import router from "@/router"
import Application from "@/base_lib/helper/app"
import socket from "@/base_lib/plugins/socket"
import VueSocketIO from "vue-socket.io"
import roles from "@/store/roles"
import teams from "@/store/teams"
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate"
import vuePersist from "@/base_lib/store/persist"
import app from "@/base_lib/store/module/app"
import auth from "@/base_lib/store/module/auth"
import { loadScript } from "vue-plugin-load-script"

Vue.config.productionTip = false

// Init vuesax
vuesax()

// Init store
const store = new Vuex.Store({
  modules: {
    teams,
    roles,
    app,
    auth,
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [createPersistedState(vuePersist)],
})
Vue.prototype.$stateManagement = store

// Init axios
Vue.prototype.$http = createAxios()

// Vue Socket
const mySocket = socket.setupSocketConnection()
Vue.use(new VueSocketIO({
  debug: false,
  connection: mySocket,
  vuex: {
    store,
    actionPrefix: "SOCKET_",
  }
}))

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  methods: {
    slugify: Application.helpers.slug,
    isDate: Application.helpers.isDate,
    formatDate: Application.helpers.formatDate,
    getBaseUrl: Application.helpers.getBaseUrl,
  },
  mounted() {
    Array.prototype.insert = function (index, item) {
      this.splice(index, 0, item);
    }
    Array.prototype.id = function () {
      if (this.length === 0) return this
      if (this[0]['id'] !== undefined) return this
      return this.map((item, index) => ({
        ...item,
        id: index,
      }))
    }
  },
  beforeDestroy() {
    this.$socket.disconnect()
  },
  beforeCreate() {
    loadScript("https://obocos.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-ia3qmx/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=7d32a1e1")
  }
}).$mount('#app')

window.ATL_JQ_PAGE_PROPS = {
  "triggerFunction": function(showCollectorDialog) {
    Vue.prototype.$showCollectorDialog = showCollectorDialog
  },
  fieldValues: () => {
   let values = {
     summary: 'There is an issue on:',
     description: `... is not working correctly. \n\n\nURL: ${window.location.href}.\nUser Agent: ${navigator.userAgent}.\nScreen Resolution: ${screen.width}x${screen.height}`,
     priority: '3'
   }

   return values
  },
};
