<template>
  <CenterContent :class-object="['grey darken-1 pa-2']">
    <sp :class-object="['white--text']">{{ this.$route.path }} is not implemented yet</sp>
  </CenterContent>
</template>

<script>
import CenterContent from "@/base_lib/components/container/CenterContent"
import Sp from "@/base_lib/components/container/SP"
import pageMixin from "@/base_lib/data/mixins/pageMixin"

export default {
  name: "Generic",
  components: {Sp, CenterContent},
  mixins: [pageMixin],
}
</script>

<style scoped>

</style>