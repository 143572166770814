import baseProps from "@/base_lib/data/props/base"

const bottomSheetProps = {
    ...baseProps,
    inset: {
        type: Boolean,
        default: true,
        required: false,
    },
    height: {
        type: Number,
        default: 400,
        required: false,
    },
}

export default bottomSheetProps
