import baseProps from "@/base_lib/data/props/base"

const simpleDataTableProps = {
    ...baseProps,
    title: {
        type: String,
        required: false,
        default: null,
    },
    height: {
        type: Number,
        required: false,
        default: null,
    },
    fixedHeader: {
        type: Boolean,
        required: false,
        default: false,
    },
    items: {
        type: Array,
        required: false,
        default: () => [],
    },
    customHeader: {
        type: Array,
        required: false,
        default: () => [],
    },
    density: {
        type: Boolean,
        required: false,
        default: false,
    }
}

export default simpleDataTableProps
