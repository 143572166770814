<template>
  <div v-bind:class="computedDivClass">
    <v-radio-group :row="horizontal">
      <v-radio
          v-for="n in buttons"
          :color="color"
          :key="n"
          :label="`${n}`"
          :value="n"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import ComponentMixin from "@/base_lib/data/mixins/componentMixin"
import radioProps from "@/base_lib/data/props/radio"

export default {
  name: "Radio",
  mixins: [ComponentMixin],
  props: radioProps,
}
</script>

<style scoped>

</style>