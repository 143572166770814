<template>
  <CenterContent size="xs12">
    <Alert :type="response.variant" :message="response.message" />
    <Alert loading :value="percentage" :type="alert.variant" :message="alert.message" />
    <DataTable
      filterable
      header-group-by
      title="Recent Actions"
      :datatable-items="dataTableItems"
      :loading="response.loading"
      :custom-options="dataTableOptions"
      :custom-header-values="headerValues"
      :custom-actions="customActions"
      :custom-loading-type="customLoadingType"
      :custom-header-ignore="ignoreHeader">

      <template v-slot:item.request_type="{ item }">
        <button @click="onGoToPageClick(item)" text>
          {{ item.request_type }}
          <i class="bx bx-link-external" />
        </button>
      </template>

      <template v-slot:item.requested="{ item }">
        <LoadingCard v-if="item.data === '###'" :classObject="['px-4', 'py-0']" :loading="item.data === '###'" type="list-item">
          {{ item.data }}
        </LoadingCard>
        <TooltipLabel v-else-if="item.data_tooltip && item.data_tooltip !== ''" top :label="item.data_tooltip">
          {{ item.data }}
        </TooltipLabel>
        <span v-else>{{ item.data }}</span>
      </template>

      <template v-slot:item.result="{ item }">
        <LoadingCard v-if="item.message === '###'" :classObject="['px-4', 'py-0']" :loading="item.message === '###'" type="list-item">
          {{ item.message }}
        </LoadingCard>
        <TooltipLabel v-else-if="item.message_tooltip && item.message_tooltip !== ''" top :label="item.message_tooltip">
          {{ item.message }}
        </TooltipLabel>
        <span v-else>{{ item.message }}</span>
      </template>

      <template v-slot:item.created_at="{ item }">
        <TooltipLabel top :label="printableDate(item.created_at)">
          {{ printableDate(item.created_at) }}
        </TooltipLabel>
      </template>
    </DataTable>
  </CenterContent>
</template>

<script>
import CenterContent from "@/base_lib/components/container/CenterContent"
import DataTable from "@/base_lib/components/container/DataTable"
import PageMixin from "@/base_lib/data/mixins/pageMixin"
import RequestService from "@/services/RequestService"
import {mapGetters} from "vuex"
import Select from "@/base_lib/components/form/Select"
import Alert from "@/base_lib/components/view/Alert"
import Button from "@/base_lib/components/form/Button"
import TooltipLabel from "@/base_lib/components/container/TooltipLabel"
import getQueryParams from "@/data/view/getQueryParams"
import GenerateServices from "@/services/GenerateServices"
import {camelizeKeys} from "@/base_lib/helper/utils"
import LoadingCard from "@/base_lib/components/view/LoadingCard.vue"

export default {
  name: "Welcome",
  components: {LoadingCard, TooltipLabel, Button, DataTable, CenterContent, Select, Alert },
  mixins: [PageMixin],
  computed: {
    ...mapGetters({
      account: 'auth/account',
      isAdmin: 'auth/isAdmin',
    }),
    customActions() {
      return [
        {
          title: "Download",
          click: this.onDownloadClick,
          isRendered: (item) => {
            return item.status.toLowerCase() === "completed" && !!item.custom_data.cloakify === false && !this.noDownloadTypes.includes(item.type)
          }
        },
        {
          title: "Standard",
          click: this.onDownloadClick,
          isRendered: (item) => {
            return item.status.toLowerCase() === "completed" && !!item.custom_data.cloakify && !this.noDownloadTypes.includes(item.type)
          }
        },
        {
          title: "Cloakify",
          click: this.onCloakifyClick,
          isRendered: (item) => {
            return item.status.toLowerCase() === "completed" && !!item.custom_data.cloakify && !this.noDownloadTypes.includes(item.type)
          }
        },
        {
          title: "View Tickets",
          click: this.onGoToPageClick,
          isRendered: (item) => {
            return item.status.toLowerCase() === "completed" && this.refreshTypes.includes(item.type) && item.type !== 'REFRESH_CREDITS'
          }
        },
        {
          title: "View",
          click: this.onGoToPageClick,
          isRendered: (item) => {
            return item.status.toLowerCase() === "completed" && item.type === 'REFRESH_CREDITS'
          }
        },
        {
          title: "Retry",
          click: this.onRetryClick,
          isRendered: (item) => {
            return item.status.toLowerCase() === 'failed' && !this.noRetryTypes.includes(item.type)
          }
        }
      ]
    },
    customLoadingType() {
      return "table-heading, table-thead, table-tbody, table-tfoot"
    },
  },
  data: () => ({
    localItems: [],
    dataTableItems: [],
    ignoreHeader: ['type'],
    headerValues: ['editor', 'request_type', 'requested', 'status', 'result', 'created_at', 'action', '_search'],
    dataTableOptions: {
      page: 1,
      itemsPerPage: 30,
      itemsPerPageOptions: [30, 50, 100],
      sort: ["created_at"],
      desc: true,
    },
    editors: [],
    percentage: null,

    // Data
    noRetryTypes: ["CREATE_BALLPARK_ACCOUNT"],
    noDownloadTypes: ["GENERATE_QR_ASYNC", "INVENTORY_API_ASYNC", "INVENTORY_APP_ASYNC", "INVENTORY_WEB_ASYNC", "RESET_ACCOUNT_PASSWORD", "BALLPARK_VERIFY_EMAIL", "CREATE_BALLPARK_ACCOUNT", "SIDE_DMS", "REFRESH_CREDITS"],
    refreshTypes: ["GENERATE_QR_ASYNC", "INVENTORY_API_ASYNC", "INVENTORY_APP_ASYNC", "INVENTORY_WEB_ASYNC", "REFRESH_CREDITS", "SIDE_DMS"]
  }),
  methods: {
    fetch() {
      const isAdmin = !!this.account?.is_admin

      RequestService.history([], isAdmin).then(response => {
        this.decorateItems(response)
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.response.loading = false
      })
    },
    fetchLight() {
      this.response.loading = true
      const isAdmin = !!this.account?.is_admin

      RequestService.historyLight([], isAdmin).then(response => {
        console.log("Loaded")
        this.decorateLightItems(response)
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.response.loading = false
        this.fetch()
      })
    },
    decorateLightItems(results) {
      let items = results.data.data.list.map(item => ({
        ID: item.id,
        editor: [item.editor.first_name, item.editor.last_name].join(" "),
        editor_object: item.editor,
        request_type: item.custom_data.title ?? "qr",
        type: item.request_type,
        data: "###",
        data_tooltip: "###",
        request_id: item.request_uuid,
        status: item.request_status,
        status_tooltip: "###",
        created_at: item.created_at,
        timestamp: new Date(item.created_at).getTime(),
        message: "###",
        message_tooltip: "###",
        custom_request_type: item.custom_data?.title,
        custom_data: item.custom_data,
        _item: item,
        _search: JSON.stringify([item.request_data, item.decorated_response, item.extra_data]),
      }))
      this.localItems = items
      this.dataTableItems = items
      this.editors = items.map(item => item.editor).filter( (item, index, array) => array.indexOf(item) === index)
    },
    decorateItems(results) {
      let items = results.data.data.list.map(item => ({
        ID: item.id,
        editor: [item.editor.first_name, item.editor.last_name].join(" "),
        editor_object: item.editor,
        request_type: item.custom_data?.title ? item.custom_data.title : "qr",
        type: item.request_type,
        data: this.decorateForData(item),
        data_tooltip: this.decorateForDataTooltip(item),
        request_id: item.request_uuid,
        status: item.request_status,
        status_tooltip: `${item.created_at} - ${item.request_uuid} - ${this.decorateForMessageTooltip(item)}`,
        created_at: item.created_at,
        timestamp: new Date(item.created_at).getTime(),
        message: this.decorateForMessage(item),
        message_tooltip: this.decorateForMessageTooltip(item),
        custom_request_type: item.custom_data?.title,

        custom_data: item.custom_data,
        _item: item,
        _search: JSON.stringify([item.request_data, item.decorated_response, item.extra_data]),
      }))
      this.localItems = items
      this.dataTableItems = items
      this.editors = items.map(item => item.editor).filter( (item, index, array) => array.indexOf(item) === index)
    },
    decorateForData(item) {
      if (item.request_type === "PK_PASS") {
        return `${item.extra_data?.file_list?.length ?? 0} pkpass`
      } else if (this.refreshTypes.includes(item.request_type)) {
        if (item.request_data.accounts) {
          return `${item.request_data.accounts?.length ?? 0} accounts`
        } else if (item.request_data.links) {
          return `${item.request_data.links?.length ?? 0} links`
        }
      } else if (item.request_type === "GENERATE_QR") {
        return `${item.request_data.length ?? 0} tickets`
      } else if (item.request_type === "BALLPARK_VERIFY_EMAIL") {
        return item.request_data.account_user
      } else if (item.request_type === "CREATE_BALLPARK_ACCOUNT") {
        return item.request_data.account_user
      } else if (item.request_type === "RESET_ACCOUNT_PASSWORD") {
        return item.request_data.account_user
      } else if (item.request_type === "SIDE_DMS") {
        return `${item.request_data.links.length} links`
      } else if (item.request_type === "WEB_PRINT_TICKET") {
        return `${item.request_data.accounts?.length} tickets`
      } else if (item.request_type === "GENERATE_CSV") {
        return `${item.request_data.list.length} tickets`
      } else if (item.request_type === "REFRESH_INVOICE") {
        return `${item.request_data.accounts?.length ?? 0} accounts`
      }

      return "-"
    },
    decorateForDataTooltip(item) {
      if (item.request_type === "PK_PASS") {
        let files = item.extra_data?.file_list?.length > 5 ? item.extra_data.file_list?.slice(0,5) : item.extra_data.file_list
        let addMore = item.extra_data?.file_list?.length > 5 ? "<br/>..." : ""
        return files.join("<br />") + addMore
      } else if (this.refreshTypes.includes(item.request_type)) {
        if (item.request_data.accounts) {
          let accounts = Array.isArray(item.request_data.accounts) ? item.request_data.accounts : []
          return accounts.map(account => account.account_user || account.account_performer).join("<br />")
        } else if (item.request_data.links) {
          let links = Array.isArray(item.request_data.links) ? item.request_data.links : []
          return links.join("<br />")
        }
      } else if (item.request_type === "GENERATE_QR") {
        let tickets = item.request_data.length > 5 ?  item.request_data.slice(0, 5) : item.request_data
        let addMore = item.request_data.length > 5 ? "<br/>..." : ""
        return tickets.map(ticket => [ticket.event, ticket.section, ticket.row, ticket.seat].join(", ")).join("<br/>") + addMore
      } else if (item.request_type === "GENERATE_CSV") {
        let list = item.request_data?.list?.length > 5 ? item.request_data?.list?.slice(0, 5) : item.request_data.list
        let addMore = item.request_data?.list?.length > 5 ? "<br/>..." : ""
        let tickets = camelizeKeys(list)

        return tickets.map(ticket => [ticket.event ?? ticket.teams, ticket.section, ticket.row, ticket.seat].join(", ")).join("<br/>") + addMore
      } else if (item.request_type === "BALLPARK_VERIFY_EMAIL") {
        return [item.request_data.account_user, item.request_data.email_to_be_verified].join(" -> ")
      } else if (item.request_type === "CREATE_BALLPARK_ACCOUNT") {
        return [item.request_data.account_user, item.request_data.account_name, item.request_data.account_surname].join(":")
      } else if (item.request_type === "SIDE_DMS") {
        return item.request_data.links.join("<br />")
      } else if (item.request_type === "REFRESH_INVOICE") {
        let accounts = Array.isArray(item.request_data.accounts) ? item.request_data.accounts : []
        return accounts.map(account => account.account_user).join("<br />")
      } else if (item.request_type === 'WEB_PRINT_TICKET') {
        return item.request_data?.accounts?.map(account => account.account_user + " -> " +account.account_tickets?.join(", ")).join("<br />")
      }

      return null
    },
    decorateForMessage(item) {
      if (item.request_type === "GENERATE_QR") {
        if (item.request_status === 'requested') {
          return ""
        }
        return `Generated ${item.extra_data?.file_list?.length / 2} tickets`
      } else if (item.request_type === "PK_PASS") {
        if (item.request_status === 'requested') {
          return ""
        } else if (item.request_status === 'failed') {
          return "We couldn't generate any ticket."
        }
        let number = item.extra_data?.file_count ?? item.extra_data?.file_list?.length
        return `Generated ${number} tickets`
      } else if (item.request_type === "SIDE_DMS") {
        if (item.request_status === 'requested') {
          return ""
        }
        if (item.decorated_response?.length === 0) {
          return item.api_response_message ?? "We couldn't find pass"
        } else {
          return `Found ${item.decorated_response?.length ?? 0} tickets`
        }
      } else if (this.refreshTypes.includes(item.request_type)) {
        return item.api_response_message
      } else if (item.request_type === "BALLPARK_VERIFY_EMAIL") {
        return item.request_status === "completed" ? 'Account linked successfully' : "Can't linked"
      } else if (item.request_type === "CREATE_BALLPARK_ACCOUNT" || item.request_type === "RESET_ACCOUNT_PASSWORD") {
        if (!!item.decorated_response.response) {
          return item.decorated_response?.response?.slice(0, 20) + "..."
        }
        return ""
      } else if (item.request_type === "GENERATE_CSV") {
        if (item.request_status === "completed") {
          return `We generated CSV file for ${item.request_data.list.length} tickets`
        } else {
          return "Not able to generate CSV file"
        }
      } else if (item.request_type === "GENERATE_QR_ASYNC") {
        return `Found ${item.decorated_response.length} tickets`
      } else if (item.request_type === "REFRESH_INVOICE") {
        return item.api_response_message
      } else if (item.request_type === "WEB_PRINT_TICKET") {
        return item.api_response_message
      }

      return "-"
    },
    decorateForMessageTooltip(item) {
      if (item.request_status !== "failed") { return "" }

      if (item.request_type === "CREATE_BALLPARK_ACCOUNT" || item.request_type === "RESET_ACCOUNT_PASSWORD") {
        return item.decorated_response.response || ""
      } else if (item.request_type === "SIDE_DMS") {
        if (item.request_status === 'requested') {
          return ""
        }
        return item.decorated_response.map(event => event.event).join("<br/>")
      } else if(item.request_type === "REFRESH_CREDITS") {
        if (item.api_response_errors?.length > 0) {
          return item.api_response_errors?.map(error => error?._error_message).join("<br />")
        } else {
          return item.decorated_response.map(item => `${item.account_user} -> ${item.__account_credit_amount} ${item.__account_credit_currency}`).join("<br/>")
        }
      } else if (this.refreshTypes.includes(item.request_type)) {
        if (item.request_status === 'failed') {
          if (item.api_response_errors?.length > 0) {
            return item.api_response_errors?.map(error => error?.account_user + ": " + error?._error_message).join("<br />")
          }
        } else if (item.request_status === 'completed') {
          return item.decorated_response.slice(0, 4).map(event => event.event_detail?.__event_name).join("<br/>") + "<br />..."
        }
      } else if (item.request_type === "REFRESH_INVOICE") {
        if (item.request_status === 'failed') {
          return item.api_response_errors?.map(error => `${error?.account_user} -> ${error?._error_message}`).join("<br />")
        } else if (item.request_status === 'completed') {
          return item.decorated_response?.map(item => `${item.invoice_descriptions}`).join("<br />")
        }
      } else if (item.request_type === "WEB_PRINT_TICKET") {
        if (item.api_response_errors?.length > 0) {
          return item.api_response_errors?.map(error => error?.account_user + ": " + error?._error_message).join("<br />")
        }
      } else if (item.request_type === 'PK_PASS') {
        return item.api_response_message ?? ""
      }

      return ""
    },

    onDownloadClick(item) {
      this.downloadFile(item._item.extra_data?.file_name ?? item.request_id, item.request_id, item._item.request_type, false)
    },
    onCloakifyClick(item) {
      this.downloadFile(item._item.extra_data?.file_name, item.request_id, item._item.request_type, true)
    },
    downloadFile(fileName, requestId, requestType, cloakify) {
      this.response.message = null
      if (fileName === null || requestId === null) return

      this.alert.loading = true
      this.alert.message = "Please stand by"

      let callback = (percentage) => {
        if (percentage > 0) {
          this.percentage = percentage
          this.alert.loading = false
        } else if (percentage === 0) {
          this.alert.loading = true
        }
      }

      let request = [
        GenerateServices.download(requestId, requestType, callback),
      ]

      if (cloakify) {
        request = [
            GenerateServices.download(requestId + "_cloakify", requestType, callback)
        ]
      }

      Promise.all(request).then(responses => {
        responses.forEach( (response, index) => {
          let fn = !!cloakify ?  requestId + "_cloakify.zip" : (this.fileName ?? this.getFileName(fileName))
          let reader = new FileReader()
          reader.onload = () => {
            let type = requestType === 'GENERATE_CSV' ? 'text/csv' : 'application/zip'
            let blob = new Blob([response.data], {type})
            let url = window['URL'].createObjectURL(blob)
            let a = document.createElement('a')
            a.href = url
            a.download = fn
            a.click()
          }
          reader.readAsBinaryString(response.data)
        })
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.alert.loading = false
        this.alert.message = null
        this.percentage = null
      })
    },
    onGoToPageClick(item) {
      let query = {}

      if (item.status === "completed" && this.refreshTypes.includes(item.type)) {
        query = { history: item.request_id }
      }
      let path = `${item._item.custom_data.full_path.split("?")[0]}`

      this.$router.push({ path, query })
    },
    onRetryClick(item) {
      let path = `${item._item.custom_data.full_path.split("?")[0]}`
      let query = getQueryParams(item._item.request_type, item)

      this.$router.push({ path, query })
    },
  },
  mounted() {
    this.fetchLight()
  },
}
</script>
