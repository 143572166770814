<template>
  <LoadingCard v-bind:class="computedDivClass" :loading="loading" :type="loadingSkeleton">
    <slot name="header">
      <v-row class="mx-0" v-bind:class="{'mb-4': $slots.bigFilter === undefined}">
        <v-col :cols="$slots.filter ? 3 : 4" class="pb-0">
          <slot name="customTitle" :title="title">
            <sp class="text-h6 font-weight-bold pt-3">{{ computedTitle }}</sp>
          </slot>
        </v-col>

        <v-col cols="3" class="pb-0" v-if="$slots.filter">
          <slot name="filter"></slot>
        </v-col>
        <v-col :cols="$slots.filter ? 3 : 4" class="pb-0" v-if="$props.groupBy != null">
          <Select @input="onGroupSelected" :label="computedGroupByTitle" :items="groupingItems" clearable :required="false" />
        </v-col>

        <v-col :cols="$slots.filter ? 3 : 4" class="pb-0" v-if="searchable" :offset="($slots.filter === undefined && $props.groupBy === null) ? 4 : 0">
          <v-text-field label="Search" append-icon="bx bx-search" v-model="query" single-line hide-details />
        </v-col>
      </v-row>
      <v-row class="mx-2 mb-4" v-if="$slots.bigFilter">
        <slot name="bigFilter"></slot>
      </v-row>
    </slot>

    <v-data-table
      v-bind:class="computedClass"
      :search="query"
      :items="computedItems"
      :headers="selectedHeader"
      :show-expand="expandable"
      :show-select="selectable"
      :single-select="singleSelect"
      :item-key="itemKey"
      :expanded.sync="expandedSection"
      :options.sync="dataTableOptions"
      :page.sync="dataTableOptions.page"
      :sort-by.sync="dataTableOptions.sort"
      :sort-desc.sync="dataTableOptions.desc"
      :items-per-page.sync="dataTableOptions.itemsPerPage"
      :footer-props="{itemsPerPageOptions: dataTableOptions.itemsPerPageOptions}"
      @toggle-select-all="onSelectAll"
      v-model="selectedRow"
      v-bind="$attrs">

      <!-- Expandable items -->
      <template v-if="expandable" v-slot:item.data-table-expand="{ item, isSelected, select }">
        <v-btn fab color="gray" width="20" height="20" @click="onExpandClick(item)">
          <v-icon small dark v-if="expandedSection.includes(item)">mdi-minus</v-icon>
          <v-icon small dark v-if="expandedSection.includes(item) === false">mdi-plus</v-icon>
        </v-btn>
      </template>
      <!--/ Expandable items -->

      <!-- Expandable slot -->
      <template v-slot:expanded-item="{ item }">
        <td class="px-8" colspan="12" elevation="0" bgcolor="#d3d3d3">
          <slot name="expandedItem" :item="item">
            Please use 'expandedItem' to use expandable cells
          </slot>
        </td>
      </template>
      <!--/ Expandable slot -->

      <!-- Filterable header values -->
      <template v-if="headerGroupBy" v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
        <!-- Show header values -->
        <div style="display: inline-block; padding: 16px 0;">{{ getHeader(header.value) }}</div>
        <!-- /// --->

        <div v-if="hasGroupByFunction(header.value)" style="float: right; margin-top: 8px; width: 20px">
          <!-- Show item menu -->
          <v-menu
            right fixed offset-y
            :close-on-content-click="false"
            :nudge-width="200"
            transition="slide-y-transition"
            style="position: absolute; right: 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                <v-icon small
                  color="default">
                  mdi-filter-variant
                </v-icon>
              </v-btn>
            </template>
            <v-list flat dense class="pa-0">
              <v-list-item-group
                multiple
                class="py-2"
                @change="onHeaderGroupByChange"
                v-model="headerFilterItems[header.value]">
                <template v-for="item in getHeaderValueItems(header.value)">
                  <v-list-item
                    :key="`${item}`"
                    :value="item"
                    :ripple="false">
                    <template v-slot:default="{ active, toggle }">
                      <v-list-item-action>
                        <v-checkbox
                          dense
                          color="primary"
                          :input-value="active"
                          :true-value="item"
                          :ripple="false" />
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-text="item" />
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <!-- /// -->
        </div>
      </template>
      <!-- Filterable header values -->

      <!-- Dynamic cell values -->
      <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">
        <slot :name="[`item.${header.value}`]" :item="item">
          {{ getVal(item, header.value) }}
        </slot>
      </template>
      <!-- Dynamic cell values -->

      <!-- Filtering header values -->
      <template v-if="(filterable & filteredHeader.length > 0) || ($slots.action)" v-slot:footer.prepend>
        <v-select v-if="(filterable & filteredHeader.length > 0)" @change="onSelectChange" dense multiple v-model="filteredHeader" class="w150 mt-4" prepend-icon="bx bx-filter" :items="headers" :menu-props="{ maxWidth: '350'}">
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0" class="mb-1">
              {{ filteredHeader.length }} columns
            </v-chip>
          </template>
        </v-select>

        <!-- Footer Action -->
        <slot name="action" v-if="$slots.action"></slot>
        <!--/ Footer Action -->
      </template>
      <!--/ Filtering header values-->

      <!-- Custom Actions -->
      <template v-if="customActions.length > 0" v-slot:item.action="{ item }">
        <Button v-for="(button, index) in customActions" text outlined small
          v-if="button.isRendered?.(item) ?? true"
          :key="index"
          :title="button.title"
          :class-object="['float-left', 'ml-1']"
          @onClick="button.click(item)"></Button>
      </template>
      <!--/ Custom Actions -->

      <!-- Default slots (Status & Account Type & Created At & Updated At) -->
      <template v-if="defaultSlots" v-slot:item.status="{ item }">
        <span v-if="item.status == null"></span>
        <span v-else-if="item.status.toLowerCase() === 'y'">Active</span>
        <span v-else-if="item.status.toLowerCase() === 'n'">Passive</span>

        <TooltipLabel v-else-if="item.status_tooltip" top :label="item.status_tooltip">
          <span v-if="item.status === 'requested'" class="grey--text font-weight-bold darken-4">{{ item.status }}</span>
          <span v-else-if="item.status === 'failed'" class="red--text font-weight-bold darken-2">{{ item.status }}</span>
          <span v-else-if="item.status === 'completed'" class="green--text font-weight-bold darken-2">{{ item.status }}</span>
        </TooltipLabel>

        <TooltipLabel v-else-if="item.request_id" top :label="item.request_id">
          <span v-if="item.status === 'requested'" class="grey--text font-weight-bold darken-4">{{ item.status }}</span>
          <span v-else-if="item.status === 'failed'" class="red--text font-weight-bold darken-2">{{ item.status }}</span>
          <span v-else-if="item.status === 'completed'" class="green--text font-weight-bold darken-2">{{ item.status }}</span>
        </TooltipLabel>

        <!-- Extended slot -->
        <span v-else-if="item.status === 'requested'" class="grey--text font-weight-bold darken-4">{{ item.status }}</span>
        <span v-else-if="item.status === 'failed'" class="red--text font-weight-bold darken-2">{{ item.status }}</span>
        <span v-else-if="item.status === 'completed'" class="green--text font-weight-bold darken-2">{{ item.status }}</span>
        <span v-else>{{ item.status }}</span>
      </template>

      <!-- Editor for QR -->
      <template v-if="defaultSlots" v-slot:item.editor="{ item }">
        <TooltipLabel v-if="item.editor_object?.dashboard_user" top :label="item.editor_object?.dashboard_user">
          {{ item.editor }}
          <v-chip v-if="item.editor_object.is_admin" class="px-1 ma-0" label x-small>
            admin
          </v-chip>
        </TooltipLabel>
        <span v-else>API Test</span>
      </template>
      <!-- Editor for QR -->

      <template v-if="defaultSlots" v-slot:item.accountType="{ item }">
        <span v-if="item.accountType.toLowerCase() === 'fan'">Fan</span>
        <span v-else-if="item.accountType.toLowerCase() === 'broker'">Broker</span>
        <span v-else>{{ item.accountType }}</span>
      </template>

      <template v-if="defaultSlots && defaultTimeSlots" v-slot:item.created_at="{ item }">
        <span>{{ printableDate(item.created_at) }}</span>
      </template>

      <template v-if="defaultSlots && defaultTimeSlots" v-slot:item.updated_at="{ item }">
        <span>{{ printableDate(item.updated_at) }}</span>
      </template>

      <template v-if="!defaultTimeSlots" v-slot:item.created_at="{ item }">
        <TooltipLabel top :label="item.created_at">
          <span>{{ printableDate(item.created_at) }}</span>
        </TooltipLabel>
      </template>

      <template v-if="!defaultTimeSlots" v-slot:item.updated_at="{ item }">
        <span>{{ fromNowDate(item.updated_at) }}</span>
      </template>
      <!--/ Default slots -->

      <!-- Deletable slot -->
      <template v-if="deletable" v-slot:item.remove="{ item }">
        <Button button-icon="bx bx-minus-circle" text />
      </template>
      <!--/ Deletable slot -->

    </v-data-table>

    <slot name="footer"></slot>
  </LoadingCard>
</template>

<script>
import ComponentMixin from "@/base_lib/data/mixins/componentMixin"
import DataTableMixin from "@/base_lib/data/mixins/dataTableMixin"
import datatableProps from "@/base_lib/data/props/datatableProps"
import LoadingCard from "@/base_lib/components/view/LoadingCard"
import sp from "@/base_lib/components/container/SP"
import Button from "@/base_lib/components/form/Button"
import PageMixin from "@/base_lib/data/mixins/pageMixin"
import Select from "@/base_lib/components/form/Select"
import TooltipLabel from "@/base_lib/components/container/TooltipLabel"
import Copyable from "@/base_lib/components/container/Copyable"

export default {
  name: "DataTable",
  components: {Select, LoadingCard, sp, Button, TooltipLabel, Copyable},
  mixins: [ComponentMixin, DataTableMixin, PageMixin],
  emits: ['click', 'onSelect'],
  props: datatableProps,
  computed: {
    loadingSkeleton() {
      if (this.customLoadingType) {
        return this.customLoadingType
      }
      return this.getBigTableSkeleton()
    },
  },
  data: () => ({
    query: null,
  }),
}
</script>

<style>
.w150 {
  max-width: 250px !important;
}
.smaller td {
  font-size: 12px !important;
  height: 0 !important;
  padding: 6px !important;
}

.v-data-table-header th {
  white-space: nowrap;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: .5rem !important;
}
</style>
