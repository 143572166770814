<template>
<CenterContent size="xs12">
  <Sp :class="['mb-3', 'text-subtitle-1', 'font-weight-light']">{{ viewHelpers.description }}</Sp>
  <v-card class="pa-2" outlined>
    <TextBox
      :value="accounts"
      :label="viewHelpers.textBoxLabel"
      :hint="viewHelpers.textBoxHint"
      :error="textBoxError"
      @input="onAccountChange" />
    <Alert type="error" outlined border="left" :message="warningError" />
    <Alert outlined :type="response.variant" border="left" :message="response.message" />
    <Alert :loading="alert.loading" :type="alert.variant" :message="alert.message" />
    <Alert type="success" :message="successMessage">
      <Button title="Download" outlined small block @onClick="downloadFile"></Button>
    </Alert>
    <v-row>
      <v-col cols="6">
        <Button
          title="Scrape QR Data"
          :loading="alert.loading"
          :class-object="['px-10']"
          @onClick="onButtonClick"
          prepend-icon="bx bx-refresh"
          outlined />
      </v-col>
      <v-col cols="6">
        <Button
          title="Show My History"
          :loading="alert.loading"
          :class-object="['px-10', 'float-right']"
          @onClick="onHistoryClick"
          prepend-icon="bx bx-history"
          outlined />
      </v-col>
    </v-row>
    <TicketList
      v-if="ticketItems != null"
      :filterable="false"
      :items="ticketItems"
      :class-div-object="['my-10']">
      <template v-slot:footer="{ hasSelectedRow, selectedRows }">
        <v-row class="mt-2">
          <v-col cols="4">
            <Button
              text outlined x-large
              title="Download as Spreadsheet"
              button-icon="bx bx-list-ol"
              @onClick="() => onCSVClick(selectedRows)"
              :disabled="!hasSelectedRow"
              :loading="alert.loading" />
          </v-col>
        </v-row>
      </template>
    </TicketList>
  </v-card>
  <BottomSheet
    v-model="historySheet"
    class="mb-10"
    @onClose="onHistoryClose">
    <Alert type="error" :message="history.message" />
    <DataTable
      title="History"
      custom-loading-type="table-thead, table-tbody, table-tfoot"
      filterable
      group-by="status"
      :default-slots="false"
      :loading="history.loading"
      :custom-options="datatableOptions"
      :custom-actions="customActions"
      :custom-header-values="historyHeaderValues"
      :datatable-items="historyItems">
      <template v-slot:item.editor="{ item }">
        <TooltipLabel v-if="item.editor.dashboard_user" top :label="item.editor.dashboard_user">
          {{ [item.editor.first_name, item.editor.last_name].join(" ") }}
          <v-chip v-if="item.editor.is_admin" class="px-1 ma-0" label x-small>
            admin
          </v-chip>
        </TooltipLabel>
        <span v-else>API Test</span>
      </template>

      <template v-slot:item.request="{ item }">
        <TooltipLabel v-if="item.request" top :label="`${item.request.links.join('<br />')}`">
          {{ item.request.links.length }} links
        </TooltipLabel>
        <span v-else>No links</span>
      </template>

      <template v-slot:item.status="{ item }">
        <TooltipLabel top :label="item.request_id">
          <span v-if="item.status === 'requested'" class="grey--text font-weight-bold darken-4">{{ item.status }}</span>
          <span v-else-if="item.status === 'failed'" class="red--text font-weight-bold darken-2">{{ item.status }}</span>
          <span v-else-if="item.status === 'completed'" class="green--text font-weight-bold darken-2">{{ item.status }}</span>
          <span v-else>{{ item.status }}</span>
        </TooltipLabel>
      </template>

    </DataTable>
  </BottomSheet>
</CenterContent>
</template>

<script>
import {baseValidation} from "@/data/view/validate"
import helper from "@/utils/viewHelper"
import CenterContent from "@/base_lib/components/container/CenterContent"
import TextBox from "@/base_lib/components/form/TextBox"
import PageMixin from "@/base_lib/data/mixins/pageMixin"
import Alert from "@/base_lib/components/view/Alert"
import HistoryAction from "@/view/components/HistoryAction"
import Button from "@/base_lib/components/form/Button"
import TooltipLabel from "@/base_lib/components/container/TooltipLabel"
import TicketmasterService from "@/services/TicketmasterService"
import BottomSheet from "@/base_lib/components/container/BottomSheet"
import DataTable from "@/base_lib/components/container/DataTable"
import Sp from "@/base_lib/components/container/SP"
import TicketList from "@/view/other/TicketList"
import RequestService from "@/services/RequestService"
import socketMixin from "@/data/mixins/socketMixin"
import GenerateServices from "@/services/GenerateServices"
import pageProjectMixin from "@/data/mixins/pageProjectMixin"

export default {
  name: "tmPasses",
  components: {
    TicketList, Sp, DataTable, BottomSheet,
    TooltipLabel, HistoryAction, TextBox,
    CenterContent, Alert, Button
  },
  mixins: [PageMixin, socketMixin, pageProjectMixin],
  computed: {
    customActions() {
      return [
        {
          title: "View Tickets",
          click: this.onHistoryItemClick,
          isRendered: (item) => {
            return item.status === 'completed'
          }
        },
      ]
    },
  },
  data: () => ({
    // TextBox variables
    accounts: "",
    warningError: null,
    textBoxError: "",
    textBoxErrorLines: [],
    rules: {
      separator: null,
      length: 1,
      items: ["url"],
    },

    // Loading
    alert: {
      message: null,
      variant: "info",
      loading: false,
    },

    // Ticket items
    ticketItems: null,
    successMessage: null,
    fileBuffer: null,
    fileName: null,

    // History variables
    historyItems: [],
    historySheet: false,
    historyHeaderValues: [],
    historyErrorMessage: null,
    datatableOptions: {
      page: 1,
      itemsPerPage: 5,
      itemsPerPageOptions: [5],
    },
  }),
  methods: {
    onAccountChange(accounts) {
      this.accounts = accounts
    },

    // Button Clicks
    onButtonClick() {
      this.resetHistoryId()
      this.warningError = null
      this.response.message = null
      this.textBoxError = ""
      this.textBoxErrorLines = []
      this.ticketItems = null
      this.successMessage = null

      let validateResponse = baseValidation(this.accounts, this.rules)
      if (validateResponse.success === false) {
        this.textBoxError = validateResponse.message
        return
      }

      this.warningError = validateResponse.other.map(line => line.message).join("<br/>")

      let data = {
        data: {
          links: validateResponse.validLines,
          custom_data: {
            ...this.custom_data,
            type: "scrape"
          }
        }
      }

      this.response.loading = true
      this.alert.loading = true
      this.alert.message = "Please stand by"

      TicketmasterService.sideDMS(data).then(response => {
        this.response = response.networkResponse(true)
        if (response.data.data.request_status === "requested") {
          this.socketRequestId = response.data.data.request_id
          this.alert.message = "We got your request. Scraping tickets... \nYou can close this page and check it later from history. (" + response.data.data.request_id + ")"
        } else {
          this.warningError = "An error occurred. Please try again"
          this.alert.message = null
          this.alert.loading = false
        }
        this.scrollToTop()
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      })
    },

    getTicketList(id) {
      RequestService.historyDetail(id).then(response => {
        if (this.alert.loading === false) {
          return
        }

        let data = response.data.data.decorated_response

        if (response.data.data.request_data?.links?.length > 0) {
          this.accounts = response.data.data.request_data?.links?.join("\n")
        }

        if (Array.isArray(data) && data.length > 0) {
          this.ticketItems = data?.id()
          this.response.variant = 'error'
          this.response.message = this.getError(response.data.data)
        } else if (response.data.code === 100) {
          this.ticketItems = null
          this.response.variant = 'error'
          this.response.message = this.getError(response.data.data)
        } else {
          this.response.variant = 'error'
          this.response.message = "An error occurred. Please try again."
        }

        this.alert.message = null
        this.alert.loading = false
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      })
    },

    onCSVClick(items) {
      const filteredList = items.map(i => {
        const { id, designated_id, _item, _search, ...newList } = i
        return newList
      }).map(item => ({
        team: item.team,
        event: item.event,
        section: item.section,
        row: item.row,
        seat: item.seat,
        barcode: item.barcode,
        venue: item.venue,
        date: item.date,
        time: item.time,
      }))

      let data = {
        platform: this.platform,
        list: filteredList,
        custom_data: {
          ...this.custom_data,
          title: `${this.custom_data.title} / Generate CSV`,
          type: "generateCSV"
        }
      }

      this.successMessage = null
      this.alert.message = "Loading..."
      this.alert.loading = true

      GenerateServices.generateCSV(data).then(response => {
        this.response = response.networkResponse(true)
        this.requestType = "GENERATE_CSV"
        this.onGenerateResponse(response.data)
        this.alert.message = null
        this.alert.loading = false
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }

        this.alert.message = null
        this.alert.loading = false
      })
    },
    // Generate response
    onGenerateResponse(response) {
      if (response.code !== 100) {
        this.response.variant = 'error'
        this.response.message = response.message
        return
      }

      this.fileBuffer = response.data.request_id
      this.fileName = response.data.file_name
      if (this.requestType === null) {
        this.successMessage = "QR Generated Successfully"
      } else if (this.requestType === 'GENERATE_CSV') {
        this.successMessage = "CSV Generated Successfully"
      }
      this.scrollToTop()
    },
    downloadFile() {
      this.response.message = null
      this.alert.loading = true
      this.alert.message = "Please stand by"

      GenerateServices.download(this.fileBuffer, this.requestType).then(response => {
        let reader = new FileReader()
        reader.onload = () => {
          let type = this.requestType === 'GENERATE_CSV' ? 'text/csv' : 'application/zip'
          let blob = new Blob([response.data], { type })
          let url = window['URL'].createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = this.fileName ?? this.fileBuffer
          a.click()
        }
        reader.readAsBinaryString(response.data)
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.alert.loading = false
        this.alert.message = null
      })
    },
    getError(response) {
      if (response.api_response?.transaction_response?.response_errors?.length > 0) {
        return "Error(s): <br/>".concat(response.api_response?.transaction_response?.response_errors.map(error =>
            `${error.link_code} -> ${error.error}`
        ).join("<br/>"))
      }

      return ""
    },

    // History
    onHistoryClick() {
      this.history.loading = true
      this.historySheet = true

      RequestService.history(this.viewHelpers.filter).then(response => {
        this.history = response.networkResponse(true)
        if (response.data.data.list.length > 0) {
          this.historyItems = this.getHistoryData(response.data.data.list)
          this.historyHeaderValues = Object.keys(this.historyItems[0]).filter(item => this.historyHeaderIgnore.includes(item) === false)
        }
      }).catch(error => {
        if (error.networkError === 'function') {
          this.history = error.networkError?.(error)
        } else {
          this.history = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.response.loading = false
      })
    },
    onHistoryClose() {
      this.historySheet = false
      this.historyItems = []
    },
    onHistoryItemClick(item) {
      this.accounts = null
      this.response.message = null
      let id = typeof item === 'object' ? item._item.request_uuid : item
      this.onHistoryClose()
      this.alert.loading = true
      this.alert.message = "Please stand by"
      this.getTicketList(id)
    },
    getHistoryData(items) {
      return items.map(item => ({
        request_id: item.request_uuid,
        editor: item.editor,
        request: item.request_data,
        result: `Found ${item.decorated_response?.length} tickets`,
        status: item.request_status,
        request_time: this.printableDate(item.created_at),
        action: "",
        _search: JSON.stringify(item).toLowerCase(),
        _item: item,
      }))
    },
    onSocketData(data) {
      if (data.status === 'failed') {
        this.alert.message = null
        this.alert.loading = false
        this.response.variant = 'error'
        this.response.message = this.getSocketError(data)
      } else if (data.status === 'completed') {
        this.getTicketList(data.request_id)
      }
    },
    checkParams() {
      if (this.$route.query.url) {
        this.accounts = this.$route.query.url.replaceAll(";", "\n")
      }
    },
  },
  beforeMount() {
    this.hasSocket = true
    this.viewHelpers = helper(this.$route.meta.type)
    this.accounts = this.viewHelpers.textBoxModel
  },
  mounted() {
    this.checkParams()
  },
}
</script>
