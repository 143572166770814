<template>
  <div v-bind:class="computedDivClass">
    <DataTable
      ref="ticket_table"
      header-group-by
      :selectable="selectable"
      :filterable="filterable"
      @onSelect="onRowSelected"
      :custom-computed-selectable-title="getTitle"
      :default-slots="false"
      :single-select="false"
      :custom-header-values="viewHelpers.ticketHeader"
      :custom-options="datatableOptions"
      :datatable-items="localItems"
      v-bind="$attrs">

      <template v-slot:item.account_user="{ item }">
        <TooltipLabel v-if="item.account_user_tooltip && item.account_user_tooltip !== ''" top :label="item.account_user_tooltip">
          {{ item.account_user }}
        </TooltipLabel>
        <span v-else>{{ item.account_user }}</span>
      </template>
    </DataTable>
    <slot name="footer" :hasSelectedRow="selectedRows.length !== 0" :selectedRows="selectedRows"></slot>
  </div>
</template>

<script>
import ComponentMixin from "@/base_lib/data/mixins/componentMixin"
import ticketListProps from "@/data/props/ticketListProps"
import DataTable from "@/base_lib/components/container/DataTable"
import PageMixin from "@/base_lib/data/mixins/pageMixin"
import Button from "@/base_lib/components/form/Button"
import helper from "@/utils/viewHelper"
import TooltipLabel from "@/base_lib/components/container/TooltipLabel.vue"

export default {
  name: "TicketList",
  components: {TooltipLabel, DataTable, Button},
  mixins: [ComponentMixin, PageMixin],
  props: ticketListProps,
  computed: {
    getTitle() {
      return this.title || "Ticket List"
    },
  },
  data: () => ({
    localItems: [],
    selectedRows: [],
    datatableOptions: {
      page: 1,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 25, 50, 100],
      sort: ["ticket_id"],
    },
  }),
  methods: {
    onClickGenerate() {
      this.$props.onGenerateClick?.(this.selectedRows)
    },

    onRowSelected(rows) {
      this.selectedRows = rows
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(to) {
        if (to === null) return
        this.viewHelpers = helper(this.$route.meta.type)
        this.localItems = this.viewHelpers.ticketHeaderParser(to)
        this.datatableOptions.sort = this.viewHelpers.ticketSort
      },
    },
  }
}
</script>
