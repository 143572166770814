<template>
  <v-tooltip v-bind="$attrs">
    <template v-slot:activator="{ on, attrs }">
      <span v-html="" v-bind="attrs" v-on="on" @click="click" class="customCursor">
        <slot></slot>
      </span>
    </template>
    <span v-html="getLabel" @click="click"></span>
  </v-tooltip>
</template>

<script>
import ComponentMixin from "@/base_lib/data/mixins/componentMixin"
import tooltipLabelProps from "@/base_lib/data/props/tooltipLabelProps"

export default {
  name: "TooltipLabel",
  mixins: [ComponentMixin],
  props: tooltipLabelProps,
  computed: {
    getLabel() {
      if (this.label.length > 40 && this.label.includes("<br") === false && this.label.includes("http") === false) {
        let arr = this.splitStringBySegmentLength(this.label, 40)
        return arr.join("<br />")
      }
      return this.label
    },
  },
  methods: {
    splitStringBySegmentLength(source, segmentLength) {
      const target = [];
      for (
          const array = Array.from(source);
          array.length;
          target.push(array.splice(0,segmentLength).join('')));
      return target;
    },
    click() {
      navigator.clipboard.writeText(this.label.replace(/<[^>]*>?/gm, '')).then(() => {
        console.log('Content copied to clipboard: ', this.label)
      },(error) => {
        console.error('Failed to copy', error)
      });
    }
  }
}
</script>
<style>
.customCursor {
  cursor: context-menu;
}
</style>