<template>
<DemoBase>
  Basic
  <DatePicker></DatePicker>
  Min & Max (+/- 3 days)
  <DatePicker placeholder="Min & Max" :min-date="min" :max-date="max"></DatePicker>
  Allow past pick
  <DatePicker allow-past></DatePicker>
</DemoBase>
</template>

<script>
import DemoBase from "@/base_lib/demo/base/DemoBase";
import DatePicker from "@/base_lib/components/form/DatePicker"
import {getDateWithMinusDays, getDateWithPlusDays} from "@/base_lib/helper/date";

export default {
  name: "DatePickerDemo",
  components: {DatePicker, DemoBase},
  data: () => ({
    min: getDateWithMinusDays(3),
    max: getDateWithPlusDays(3),
  }),
}
</script>

<style scoped>

</style>