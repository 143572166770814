<template>
  <div v-bind:class="computedDivClass">
    <v-card v-bind:class="computedClass" v-bind="$attrs" flat>

      <!-- Title Slot -->
      <slot v-if="title" name="title" :title="title">
        <SP class="black--text font-weight-bold mb-4">{{ title }}</SP>
      </slot>

      <!-- Content Slot -->
      <v-form :ref="computedRefName" v-if="hasForm" v-model="valid">
        <slot>No slot form-content defined</slot>
      </v-form>
      <div v-else v-bind:class="preDefinedSlotClasses">
        <slot>No slot content defined w/ form </slot>
      </div>

    </v-card>
  </div>
</template>

<script>
import componentMixin from "@/base_lib/data/mixins/componentMixin"
import SP from "@/base_lib/components/container/SP"
import cardProps from "@/base_lib/data/props/card"

export default {
  name: "Card",
  props: cardProps,
  emits: ['onFormChange'],
  components: {SP},
  mixins: [componentMixin],
  data: () => ({
    preDefinedClasses: ['pa-4'],
    preDefinedSlotClasses: [],
    valid: false,
  }),
  methods: {
    validate() {
      this.$refs[this.computedRefName].validate()
    },
    reset() {
      this.$refs[this.computedRefName].reset()
    },
  },
  watch: {
    valid: {
      immediate: false,
      handler(to) {
        this.$emit('onFormChange', { validated: to, form: !!this.form })
      },
    },
  },
  mounted() {
    this.preDefinedClasses = this.gutter ? [] : ['pa-4']
    this.preDefinedSlotClasses = this.gutter ? ['pa-4'] : []
  }
}
</script>

<style scoped>

</style>