import baseProps from "@/base_lib/data/props/base"

const pickerProps = {
    ...baseProps,
    title: {
        type: String,
        required: false,
        default: null,
    },
    items: {
        type: Array,
        required: true,
        default: () => [],
    }
}

export default pickerProps