import baseProps from "@/base_lib/data/props/base"

const actionContainerProps = {
    ...baseProps,
    actionIcon: {
        type: String,
        required: false,
        default: "mdi-history",
    },
    closeIcon: {
        type: String,
        required: false,
        default: "mdi-close",
    },
}

export default actionContainerProps