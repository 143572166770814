import { render, staticRenderFns } from "./Forgot.vue?vue&type=template&id=d9b2f950&scoped=true&"
import script from "./Forgot.vue?vue&type=script&lang=js&"
export * from "./Forgot.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9b2f950",
  null
  
)

export default component.exports