<template>
  <div>
    <p v-if="span === false" v-bind:class="computedClass" v-bind="$attrs" v-on:click="$emit('onClick')">
      <slot></slot>
    </p>
    <span v-if="span" v-bind:class="computedClass" v-bind="$attrs" v-on:click="$emit('onClick')">
      <slot></slot>
    </span>
  </div>
</template>

<script>
import componentMixin from "@/base_lib/data/mixins/componentMixin"
import spProps from "@/base_lib/data/props/sp"

export default {
  name: "sp",
  props: spProps,
  mixins: [componentMixin],
  emit: ['onClick'],
  data: () => ({
    preDefinedClasses: ['pa-0', 'ma-0'],
  }),
  beforeMount() {
    if (this.isButton) {
      this.preDefinedClasses.push('cursor')
    }
  },
}
</script>

<style>
.cursor {
  cursor: pointer;
}
</style>