import { render, staticRenderFns } from "./SelectBoxDemo.vue?vue&type=template&id=265f4edf&scoped=true&"
import script from "./SelectBoxDemo.vue?vue&type=script&lang=js&"
export * from "./SelectBoxDemo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "265f4edf",
  null
  
)

export default component.exports