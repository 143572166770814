<template>
  <div v-bind:class="computedDivClass">
    <v-menu
      ref="menu"
      v-model="menu"
      transition="scale-transition"
      v-bind="$attrs"
      :close-on-content-click="false"
      :min-width="width">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          readonly
          v-on="on"
          v-bind="attrs"
          v-model="computedDate"
          :label="placeholder"
          :prepend-icon="computedIcon" />
      </template>
      <v-date-picker
        no-title
        scrollable
        :min="minimumDate"
        :max="maximumDate"
        v-model="computedDate"
        @input="menu = false" />
    </v-menu>
  </div>
</template>

<script>
import ComponentMixin from "@/base_lib/data/mixins/componentMixin"
import datepickerProps from "@/base_lib/data/props/datepicker"
import {getDateWithPlusMonths, getTodayDate} from "@/base_lib/helper/date"

export default {
  name: "DatePicker",
  mixins: [ComponentMixin],
  props: datepickerProps,
  data:() => ({
    menu: false,
  }),
  computed: {
    computedDate: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('onPicked', newValue)
      },
    },
    minimumDate() {
      if (!!this.minDate && this.$root.isDate(this.minDate)) {
        return this.minDate
      } else if (this.allowPast === false) {
        return getTodayDate()
      }

      return null
    },
    maximumDate() {
      if (!!this.maxDate && this.$root.isDate(this.maxDate)) {
        return this.maxDate
      }

      return getDateWithPlusMonths(6)
    },
    computedIcon() {
      if (this.noIcon) {
        return null
      }
      return this.icon
    }
  },
}
</script>

<style scoped>

</style>