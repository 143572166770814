<template>
  <div v-bind:class="computedDivClass">
    <v-dialog
      :width="width"
      :value="show"
      :persistent="!cancelable"
      @click:outside="onOutsideClick"
      @close="onClose"
      v-bind="$attrs">
        <Card :title="title" :gutter="true" :has-form="hasForm" @close="onClose">

          <!-- Title slot -->
          <template #title="{ title }" v-if="title">
            <v-card-title v-if="title" class="headline grey lighten-2">
              <span>{{ title }}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
          </template>

          <!-- Content slot -->
          <div v-bind:class="computedClass">
            <slot :show="show">Please define a slot to populate content</slot>
          </div>
        </Card>
    </v-dialog>
  </div>
</template>

<script>
import modalProps from "@/base_lib/data/props/modal"
import componentMixin from "@/base_lib/data/mixins/componentMixin"
import CenterContent from "@/base_lib/components/container/CenterContent"
import Card from "@/base_lib/components/container/Card"

export default {
  name: "Modal",
  components: {CenterContent, Card},
  props: modalProps,
  mixins: [componentMixin],
  methods: {
    onOutsideClick() {
      if (this.$attrs.persistent === false) {
        this.$emit('onClose')
      }
    },
    onClose() {
      this.$emit('onClose')
    }
  },
}
</script>
