<template>
  <v-tooltip v-bind="$attrs">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab dark small
        color="grey"
        @click="onButtonClick"
        v-bind="attrs"
        v-on="on">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>
import ComponentMixin from "@/base_lib/data/mixins/componentMixin"
import tooltipButtonProps from "@/base_lib/data/props/tooltipButtonProps"

export default {
  name: "TooltipButton",
  mixins: [ComponentMixin],
  props: tooltipButtonProps,
  methods: {
    onButtonClick() {
      this.$emit('onClick')
    },
  },
}
</script>
