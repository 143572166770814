<template>
  <DemoBase>
    <Modal
      persistent
      title="Example Title"
      :has-form="true"
      :show="dialog"
      :overlay="false"
      :class-object="['pa-4']"
      @onClose="closePopup">
      <Button title="Hello" />
    </Modal>
  </DemoBase>
</template>

<script>
import PageMixin from "@/base_lib/data/mixins/pageMixin"
import Modal from "@/base_lib/components/container/Modal"
import Button from "@/base_lib/components/form/Button"
import DemoBase from "@/base_lib/demo/base/DemoBase"

export default {
  name: "ModalDemo",
  components: {DemoBase, Button, Modal},
  mixins: PageMixin,
  data: () => ({
    dialog: true,
  }),
  methods: {
    closePopup() {
      this.dialog = false
    },
  },
}
</script>

<style scoped>

</style>