<template>
  <CenterContent size="xs12">
    <SP :class="['mb-2', 'text-subtitle-1', 'font-weight-light']">{{ viewHelpers.description }}</SP>
    <v-card class="pa-3" outlined>
      <v-row>
        <v-col :cols="platform === 'seatgeek' ? 12 : 6">
          <FileUpload
            @onFileRead="onFileRead"
            label="Select Spreadsheet File (Supports .csv & .xlsx)" />
        </v-col>
        <v-col cols="3" v-if="platform !== 'seatgeek'">
          <Input @input="(value) => { additional = value }" :value="additional" :class-div-object="['mt-5', 'mb-0']" label="Additional (optional)" outlined />
        </v-col>
        <v-col cols="3" v-if="platform !== 'seatgeek'">
          <Checkbox @input="(value) => { barcodeUnder = value }" :value="barcodeUnder" :class-object="['my-4', 'pb-0']" title="Barcode under QR Image" color="primary" />
          <Checkbox @input="(value) => { onlyMobile = value }" :value="onlyMobile" :class-object="['my-n8', 'pb-0']" title="'Only Mobile' note" color="primary" />
        </v-col>
      </v-row>
      <v-row v-show="datatableItems.length > 0">
        <v-col v-show="datatableItems.length > 0" v-for="(item, index) in columns" :key="index" cols="12" md="3" class="my-n2">
          {{ item.length > 2 ? item : item[0] }} column name:
          <v-select
            required
            outlined
            placeholder="Please select column"
            :items="header"
            v-model="selectedItems[index]" />
        </v-col>
      </v-row>
      <Checkbox v-if="platform === 'seatgeek' && datatableItems.length > 0" @input="(value) => { usePostLetter = value }" :value="usePostLetter" :class-object="['my-4', 'pb-0']" title="Use Post Letter" color="primary" />
      <Alert :loading="alert.loading" :type="alert.variant" :message="alert.message" />
      <Alert :type="response.variant" :message="response.message" />
      <Alert type="success" :message="successMessage">
        <Button title="Download" outlined small block @onClick="downloadFile"></Button>
      </Alert>
      <v-row v-show="datatableItems.length > 0">
        <v-col cols="12">
          <DataTable
            header-group-by
            ref="table"
            selectable
            select-all
            custom-computed-selectable-title="File Preview"
            @onSelect="onRowSelected"
            v-show="datatableItems.length > 0"
            :custom-options="datatableOptions"
            :single-select="false"
            :default-slots="false"
            :datatable-items="datatableItems">
            <template v-slot:action>
              <Button
                text outlined
                title="Generate QR Codes"
                button-icon="bx bx-barcode"
                @onClick="onClickGenerate"
                :loading="alert.loading"
                :disabled="selectedRows.length === 0" />
            </template>
          </DataTable>
        </v-col>
      </v-row>
    </v-card>
    <HistoryAction
      has-history
      :items="historyItems"
      :history-error-message="history.message"
      :header-values="historyHeaderValues"
      :on-action-click="onActionClick"
      :on-history-click="onHistoryClick" />
  </CenterContent>
</template>

<script>
import spreadsheetTypes from "@/data/types/spreadsheet"
import CenterContent from "@/base_lib/components/container/CenterContent"
import sp from "@/base_lib/components/container/SP"
import FileUpload from "@/base_lib/components/form/FileUpload"
import DataTable from "@/base_lib/components/container/DataTable"
import Checkbox from "@/base_lib/components/form/Checkbox"
import Input from "@/base_lib/components/form/Input"
import Row from "@/base_lib/components/container/Row"
import Button from "@/base_lib/components/form/Button"
import PageMixin from "@/base_lib/data/mixins/pageMixin"
import Alert from "@/base_lib/components/view/Alert"
import GenerateService from "@/services/GenerateServices"
import HistoryAction from "@/view/components/HistoryAction"
import helper from "@/utils/viewHelper";
import RequestService from "@/services/RequestService"
import SP from "@/base_lib/components/container/SP.vue"
import moment from "moment"
import {toSnakeCase} from "@/base_lib/helper/utils"
import socketMixin from "@/data/mixins/socketMixin"
import pageProjectMixin from "@/data/mixins/pageProjectMixin"

export default {
  name: "Spreadsheet",
  components: {SP, HistoryAction, Alert, Button, Row, Input, Checkbox, DataTable, FileUpload, CenterContent, sp, },
  mixins: [PageMixin, socketMixin, pageProjectMixin],
  data: () => ({
    // DataTable
    datatableOptions: {
      page: 1,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 25, 50, 100],
    },
    header: [],
    columns: [],
    selectedItems: [],
    datatableItems: [],
    selectedRows: [],

    // Loading
    alert: {
      message: null,
      variant: "info",
      loading: false,
    },

    // QR Generation
    barcodeUnder: true,
    onlyMobile: true,
    usePostLetter: false,
    additional: null,
    fileName: null,
    importedFileName: null,

    // Success
    successMessage: null,

    // History
    historyItems: [],
    historyHeaderValues: [],
  }),
  methods: {
    // File Input
    onFileRead(header, datatableItems, fileName) {
      this.datatableItems = datatableItems?.id()
      this.header = header
      this.importedFileName = fileName
      this.resetPage()
    },

    // Data table
    onRowSelected(rows) {
      this.selectedRows = rows
    },
    resetPage() {
      if (this.header.length === 0) {
        this.successMessage = null
        this.fileBuffer = null
        this.response.message = null
        this.alert.message = null
      }
    },
    onHistoryClick() {
      RequestService.history(this.viewHelpers.filter).then(response => {
        if (response.data.data.list.length > 0) {
          this.historyItems = this.getHistoryData(response.data.data.list)
          this.historyHeaderValues = Object.keys(this.historyItems[0]).filter(item => this.historyHeaderIgnore.includes(item) === false)
        } else {
          this.historyItems = []
        }
      }).catch(error => {
        if (error.networkError === 'function') {
          this.history = error.networkError?.(error)
        } else {
          this.history = this.returnCustomError(error.toString())
        }
      })
    },
    getHistoryData(items) {
      return items.map(item => ({
        request_id: item.request_uuid,
        editor: item.editor,
        data_list: item.extra_data.file_list,
        status: item.request_status,
        created_at: item.created_at,
        action: "",
        _search: JSON.stringify(item).toLowerCase(),
        _item: item,
      }))
    },
    onActionClick(item) {
      this.fileBuffer = item.request_id
      this.downloadFile(item._item.extra_data.file_name, true)
    },
    onClickGenerate() {
      this.successMessage = null
      let rows = JSON.parse(JSON.stringify(this.selectedRows))

      if (this.columns.length !== this.selectedItems.filter(item => !!item).length) {
        this.response.variant = "error"
        this.response.message = "Please check rows"
        return
      }

      rows.map(item => {
        this.columns.forEach((col, index) => {
          item[toSnakeCase(col.toLowerCase())] = item[this.selectedItems[index].toLowerCase()]
        })
        return item
      })
      rows = rows.map(item => ({
        ...item,
        date: moment(`${item.date} ${moment().year()}`).format('M/DD/YYYY'),
      }))

      let model = {
        platform: this.platform,
        list: rows,
        barcodeUnder: this.barcodeUnder,
        onlyMobile: this.onlyMobile,
        additional :this.additional,
        imported_file_name: this.importedFileName,
        hasBarcodeAdditional: this.usePostLetter,
        custom_data: this.custom_data
      }

      this.response.loading = true
      this.alert.loading = true
      this.alert.message = "Please stand by"
      this.scrollToTop()

      this.isGenerate = true
      GenerateService.generateWithQueue(model).then(response => {
        this.handleSocketResponse(response.data.data, "Generating tickets...")
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
          this.alert.loading = false
          this.alert.message = null
        }
      })
    },

    // File processes
    onGenerateResponse(response) {
      if (response.code !== 100) {
        this.response.variant = 'error'
        this.response.message = response.message
        return
      }

      if (response.data.file_list.length > 0) {
        this.fileBuffer = response.data.request_id
        this.fileName = response.data.file_name
        this.successMessage = "QR Generated Successfully"
      }
      if (response.data.not_created_list.length > 0) {
        this.response.variant = 'error'
        this.response.message = 'There are some errors: <br />'
        this.response.message += response.data.not_created_list.map(item => `${item.account_user ?? ""} -> ${item.section}.${item.row}.${item.seat}: ${item.error_message}`).join("<br />")
      }

      this.scrollToTop()
    },
    downloadFile(fileName, fromHistory = false) {
      this.alert.loading = true
      this.alert.message = "Please stand by"

      let usableResponse = fromHistory ? this.history : this.response
      GenerateService.download(this.fileBuffer).then(response => {
        let reader = new FileReader()
        reader.onload = () => {
          let blob = new Blob([response.data], { type: 'application/zip' });
          let url = window['URL'].createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = this.fileName ?? this.getFileName(fileName);
          a.click();
        }
        reader.readAsBinaryString(response.data)
      }).catch(error => {
        if (error.networkError === 'function') {
          usableResponse = error.networkError?.(error)
        } else {
          usableResponse= this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.alert.loading = false
        this.alert.message = null
      })
    },
  },
  onSocketData(data) {
    console.log("Socket data", data)
  },
  created() {
    this.hasSocket = true
    this.viewHelpers = helper(this.$route.meta.type)
  },
  mounted() {
    this.autoScrollTop = true
    this.columns = spreadsheetTypes[this.platform]
  },
  watch: {
    header(to) {
      this.selectedItems = []
      const matched = spreadsheetTypes.match
      this.columns.forEach( (item, index) => {
        if (to.includes(item)) {
          this.selectedItems.insert(this.columns.indexOf(item), item)
        } else if (!!matched[item]) {
          let value = matched[item]
          this.selectedItems.insert(this.columns.indexOf(item), value)
        } else {
          this.selectedItems.insert(index, null)
        }
      })
    },
  }
}
</script>
