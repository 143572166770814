<template>
  <div v-bind:class="computedDivClass">
    <v-bottom-sheet
      scrollable
      :inset="inset"
      v-bind:class="computedClass"
      v-bind="$attrs"
      @input="onClose">
      <v-sheet>
        <v-card tile class="ma-0 pa-4">
          <slot>
            Empty bottom sheet
          </slot>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import ComponentMixin from "@/base_lib/data/mixins/componentMixin"
import bottomSheetProps from "@/base_lib/data/props/bottomSheet"

export default {
  name: "BottomSheet",
  mixins: [ComponentMixin],
  props: bottomSheetProps,
  emits: ['onClose'],
  methods: {
    onClose() {
      this.$emit('onClose')
    }
  },
}
</script>

<style>
.v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
  margin-left: 260px !important;
}
</style>
