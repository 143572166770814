import { render, staticRenderFns } from "./ActionContainersDemo.vue?vue&type=template&id=16b96b2d&scoped=true&"
import script from "./ActionContainersDemo.vue?vue&type=script&lang=js&"
export * from "./ActionContainersDemo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16b96b2d",
  null
  
)

export default component.exports