<template>
  <div :class="classDivObject">
    <v-speed-dial
      v-model="actionButton"
      :class="classObject"
      v-bind="$attrs">
      <template v-slot:activator>
        <v-btn
          dark fab
          v-model="actionButton"
          color="blue darken-2">
          <v-icon v-if="actionButton">{{ closeIcon }}</v-icon>
          <v-icon v-else>{{ actionIcon }}</v-icon>
        </v-btn>
      </template>
      <slot></slot>
    </v-speed-dial>
  </div>
</template>

<script>
import ComponentMixin from "@/base_lib/data/mixins/componentMixin"
import actionContainerProps from "@/base_lib/data/props/actionContainer"

export default {
  name: "ActionContainers",
  mixins: [ComponentMixin],
  props: actionContainerProps,
  data: () => ({
    actionButton: false,
  }),
}
</script>

<style scoped>

</style>