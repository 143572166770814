<template>
  <div :class="computedDivClass">
    <v-file-input
      v-bind:class="computedClass"
      outlined
      show-size
      prepend-icon=""
      append-icon="bx bx-paperclip"
      @change="onFileChange"
      :accept="accept"
      :label="label"
      :error-messages="errorMessage"
      v-bind="$attrs" />
  </div>
</template>

<script>
import fileUploadProps from "@/base_lib/data/props/fileUpload"
import ComponentMixin from "@/base_lib/data/mixins/componentMixin"
import fileReader from "@/base_lib/helper/file"

export default {
  name: "FileUpload",
  props: fileUploadProps,
  mixins: [ComponentMixin],
  emits: ['onFileRead', 'onFileError'],
  data: () => ({
    preDefinedClasses: ['mt-5'],
    errorMessage: [],
  }),
  methods: {
    onFileChange(file) {
      if (!file) {
        this.errorMessage = []
        this.$emit('onFileRead', [], [], "")
      } else {
        fileReader(file, (result, error) => {
          if (error === null) {
            this.errorMessage = []
            this.$emit('onFileRead', result.header, result.data, file.name, file)
          } else {
            this.errorMessage = [error.toString()]
            this.$emit('onFileError', error.toString(), error)
          }
        })
      }
    },
  },
}
</script>
