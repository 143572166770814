<template>
  <div v-bind:class="computedDivClass">
    <v-select
      v-model="localValue"
      v-bind:class="computedClass"
      :items="localItems"
      :rules="rules"
      :label="label"
      :item-text="calculatedText"
      :item-value="calculatedValue"
      v-bind="$attrs">
      <template v-if="custom" v-slot:item="{ item }">
        <slot name="item" :item="item">
          Custom slot is not used
        </slot>
      </template>
      <template v-else-if="isTeam" v-slot:item="{ item }">
        <span class="red--text" v-if="item.teamId === 'ballpark' ">{{ item[calculatedText].charAt(0).toUpperCase() + item[calculatedText].slice(1) }}</span>
        <span class="blue--text" v-else-if="item.teamId === 'seatgeek' ">{{ item[calculatedText].charAt(0).toUpperCase() + item[calculatedText].slice(1) }}</span>
        <span v-else> {{ item[calculatedText] }} </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import componentMixin from "@/base_lib/data/mixins/componentMixin"
import selectProps from "@/base_lib/data/props/select"
import {requiredRule} from "@/base_lib/data/validation/regex"
import SelectType from "@/base_lib/data/types/selectType"

export default {
  name: "Select",
  mixins: [componentMixin],
  props: selectProps,
  data: () => ({
    localItems: [],
    localValue: null,
  }),
  computed: {
    calculatedText() {
      switch (this.type) {
        case SelectType.Default:
          return "label"
        case SelectType.Teams:
          return "teamName"
      }
    },
    calculatedValue() {
      switch (this.type) {
        case SelectType.Default:
          return "value"
        case SelectType.Teams:
          return "id"
      }
    },
    calculatedSort() {
      switch (this.type) {
        case SelectType.Default:
          return "value"
        case SelectType.Teams:
          return "teamName"
      }
    },
    rules() {
      if (this.required) {
        return requiredRule(this.label)
      }
      return []
    },
    isTeam() {
      return this.type === SelectType.Teams
    },
  },
  methods: {
    prepareItems() {
      if (this.sorted === false || this.items.length === 0) {
        this.localItems = this.items
        return
      }

      let text = this.calculatedSort
      this.localItems = this.items.map(o => ({...o})).sort(function (a, b) {
        if (a[text] < b[text]) return -1
        if (a[text] > b[text]) return 1
        else return 0
      }).filter(item => item[text] !== null)

      if (this.type !== SelectType.Teams) return

      let firstItems = ["seatgeek", "ballpark"]
      firstItems.forEach(name => {
        let index = this.localItems.findIndex( (team) => team[text] === name)
        let item = this.localItems.splice(index, 1)[0]
        this.localItems = [item].concat(this.localItems)
      })
    }
  },
  mounted() {
    this.prepareItems()
  },
  created() {
    this.localValue = this.$attrs.value ?? ""
    this.$watch('localValue', (value) => {
      this.$emit('input', value)
    })
  },
  watch: {
    items() {
      this.prepareItems()
    },
    '$attrs.value': function (newValue) {
      this.localValue = newValue
    },
  },
}
</script>

<style scoped>

</style>
