<template>
  <div>
    <ActionContainers
      fixed bottom left
      action-icon="bx bx-play"
      :class-object="['mb-5', 'mar-lef']">
      <TooltipButton right
                     @onClick="testClick"
                     v-if="hasTestAccounts"
                     label="Test Accounts"
                     icon="bx bx-pen" />
      <TooltipButton right
                     label="History"
                     v-if="hasHistory"
                     @onClick="historyClick"
                     icon="bx bx-history" />
    </ActionContainers>
    <BottomHistory
      title="History"
      :loading="historyLoading"
      :show="historySheet"
      :items="items"
      :header-values="headerValues"
      :error-message="historyErrorMessage"
      @onAction="onActionClick"
      @onClose="onClose" />
  </div>
</template>

<script>
import ActionContainers from "@/base_lib/components/container/ActionContainers"
import TooltipButton from "@/base_lib/components/container/TooltipButton"
import historyActionProps from "@/data/props/historyActionProps"
import BottomHistory from "@/base_lib/components/container/BottomHistory"

export default {
  name: "HistoryAction",
  components: {BottomHistory, TooltipButton, ActionContainers},
  props: historyActionProps,
  data: () => ({
    historyLoading: true,
    historySheet: false,
  }),
  methods: {
    testClick() {
      this.onTestClick?.()
    },
    historyClick() {
      this.historyLoading = true
      this.historySheet = true
      this.onHistoryClick?.()
    },
    onClose() {
      this.historySheet = false
      this.historyLoading = true
      this.onBottomSheetClose?.()
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(to) {
        if (to != null && to.length > 0) {
          this.historyLoading = false
        } else if (to.length === 0) {
          this.historyLoading = true
        }
      }
    }
  }
}
</script>

<style>
.mar-lef {
  margin-left: 260px !important;
}
</style>