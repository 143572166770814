import baseProps from "@/base_lib/data/props/base"

const datatableProps = {
    ...baseProps,
    datatableItems: {
        type: Array,
        required: true,
        default: () => [],
    },
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    title: {
        type: String,
        required: false,
        default: "List",
    },
    filterable: {
        type: Boolean,
        required: false,
        default: false,
    },
    expandable: {
        type: Boolean,
        required: false,
        default: false,
    },
    searchable: {
        type: Boolean,
        required: false,
        default: true,
    },
    selectable: {
        type: Boolean,
        required: false,
        default: false,
    },
    deletable: {
        type: Boolean,
        required: false,
        default: false,
    },
    singleSelect: {
        type: Boolean,
        required: false,
        default: true,
    },
    selectAll: {
        type: Boolean,
        required: false,
        default: false,
    },
    defaultSlots: {
        type: Boolean,
        required: false,
        default: true,
    },
    defaultTimeSlots: {
        type: Boolean,
        required: false,
        default: false,
    },
    customOptions: {
        type: Object,
        required: false,
        default: () => {}
    },
    customActions: {
        type: Array,
        required: false,
        default: () => [],
    },
    customLoadingType: {
        type: String,
        required: false,
        default: null,
    },
    customHeaderValues: {
        type: Array,
        required: false,
        default: null,
    },
    customHeaderIgnore: {
        type: Array,
        required: false,
        default: () => [],
    },
    customComputedSelectableTitle: {
        type: String,
        required: false,
        default: null,
    },

    // Filter table by grouping property
    groupBy: {
        type: String,
        required: false,
        default: null,
    },
    groupByAdmin: {
        type: String,
        required: false,
        default: null,
    },
    headerGroupBy: {
        type: Boolean,
        required: false,
        default: false,
    },
}

export default datatableProps
