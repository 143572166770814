import baseProps from "@/base_lib/data/props/base"

const copyableProps = {
    ...baseProps,
    hidden: {
        type: Boolean,
        required: false,
        default: true,
    },
    icon: {
        type: String,
        required: false,
        default: "mdi-content-copy",
    },
    tooltip: {
        type: Boolean,
        required: false,
        default: true,
    },
    tooltipText: {
        type: String,
        required: false,
        default: "Click to copy"
    },
    noSnackbar: {
        type: Boolean,
        required: false,
        default: false,
    },
    snackbarText: {
        type: String,
        required: false,
        default: "Copied"
    },
    snackbarTimeout: {
        type: Number,
        required: false,
        default: 3000,
    },
    left: {
        type: Boolean,
        required: false,
        default: true,
    },
}

export default copyableProps