import sp from "@/base_lib/components/container/SP"
import InputType from "@/base_lib/data/types/inputType"
import {loadingMixin} from "@/base_lib/data/mixins/loadingMixin"
import SelectType from "@/base_lib/data/types/selectType"
import {fromNow, getDateFromUnix, getFormattedDate, getPrintableData, isValidDate} from "@/base_lib/helper/date"

const pageMixin = {
    components: {sp},
    mixins: [loadingMixin],
    computed: {
        // === Input types
        emailType: () => InputType.Email,
        passwordType: () => InputType.Password,
        alphanumericType: () => InputType.Alphanumeric,
        textType: () => InputType.Text,

        // === Select types
        selectDefaultType: () => SelectType.Default,
        selectTeamType: () => SelectType.Teams,
    },
    data: () => ({
        autoScrollTop: false,
        isFormValid: false,
        hasSocket: false,
        // Select items
        typeItems: [
            { value: 'fan', label: 'Fan'},
            { value: 'broker', label: 'Broker'}
        ],
        statusItems: [
            { value: 'y', label: 'Active'},
            { value: 'n', label: 'Passive'}
        ],
        viewHelpers: null,
        platform: null,
        custom_data: null,
        historyHeaderIgnore: [
            'editor_id', 'request_id', 'request_type', 'file_name',
            "file_list", "data_tooltip", "result_tooltip",
            "accounts_tooltip", "custom_data", 'platform',
            'updated_at', 'timestamp', 'editor_object',
        ],

        // Loading
        alert: {
            message: null,
            variant: "info",
            loading: false,
        },
    }),
    methods: {
        resetHistoryId() {
            if (Object.keys(this.$route.query).length > 0 && Object.keys(this.$route.query).includes('history')) {
                // this.$router.replace({'query': null})
            }
        },
        onFormChange({ validated }) {
            this.isFormValid = validated
        },
        handleCustomError(message) {
            this.response = {
                loading: false,
                code: 400,
                timestamp: new Date().getTime(),
                variant: 'error',
                message: message || "An error occurred, please try again",
            }
        },
        returnCustomError(message) {
            return {
                loading: false,
                code: 400,
                timestamp: new Date().getTime(),
                variant: 'error',
                message: message || "An error occurred, please try again",
            }
        },
        getDetailedError(response, onlyError = false) {
            let message = response.data.data.api_response_message || ""
            let errors = response.data.data.api_response_errors?.map(error => {
                return response.data.data.platform === 'ticketmaster' ? `${error.account_user} -> ${error._error_message}` : ``
            }) || []
            return onlyError ? errors.join("<br/>") : [message, errors.join("<br/>")].join("<br />")
        },
        scrollToTop() {
            window.scrollTo({ top: 0,  left: 0, behavior: 'smooth' })
        },
        formatDate(date) {
            if (isValidDate(date)) {
                return getFormattedDate(date)
            }
            return "Not valid date"
        },
        printableDate(date) {
            if (isValidDate(date)) {
                return getPrintableData(date)
            }
            return "Not valid date"
        },
        formattedDate(date) {
            return getDateFromUnix(date)
        },
        fromNowDate(date) {
            return fromNow(date)
        },
        getFileName(fileName) {
            let timestamp = new Date().getTime()
            if (!!fileName === false) {
                return timestamp
            }
            let __fileName = fileName.split(".")[0]
            let separator = "_"
            return [__fileName, timestamp].join(separator)
        },

        // Check routing & query parameters
        checkQueryParams() {
            if (this.$route.query.history) {
                this.onHistoryItemClick?.(this.$route.query.history)
            }
        },
        onHistoryItemClick(id) {
            // console.info("No history item click", id)
        },
    },
    watch: {
        'response.message': {
            immediate: false,
            handler() {
                if (this.autoScrollTop) {
                    this.scrollToTop()
                }
            }
        }
    },
    mounted() {
        this.platform = this.$route.params.platform
        this.custom_data = {
            platform: this.platform,
            page: this.$route.name,
            full_path: this.$route.fullPath,
            title: this.$route.meta?.title,
        }

        this.checkQueryParams()
    }
}

export default pageMixin
