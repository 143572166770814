import baseProps from "@/base_lib/data/props/base"

const cardProps = {
    ...baseProps,
    title: {
        type: String,
        required: false,
        default: null,
    },
    hasForm: {
        type: Boolean,
        required: false,
        default: true,
    },
    form: {
        type: Object,
        required: false,
        default: () => {}
    },
    gutter: {
        type: Boolean,
        required: false,
        default: false,
    }
}

export default cardProps