import { render, staticRenderFns } from "./BottomSheetDemo.vue?vue&type=template&id=06e2a189&scoped=true&"
import script from "./BottomSheetDemo.vue?vue&type=script&lang=js&"
export * from "./BottomSheetDemo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06e2a189",
  null
  
)

export default component.exports