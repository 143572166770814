<template>
  <Modal
    persistent
    :has-form="false"
    :show="show"
    :class-object="['pa-4']">
    <SP class="font-weight-bold headline">Are you sure you want to delete <span class="font-weight-black">{{ title }}</span>?</SP>
    <SP class="mt-2 text-subtitle-2">{{ computedDescription }}</SP>
    <v-spacer class="mt-4"></v-spacer>
    <Button title="Yes" :class-object="['float-left', 'green', 'white--text']" text outlined @onClick="$emit('onClickYes')" />
    <Button title="No" :class-object="['ml-2', 'red', 'white--text']" text outlined @onClick="$emit('onClickNo')" />
  </Modal>
</template>

<script>
import Modal from "@/base_lib/components/container/Modal"
import modalProps from "@/base_lib/data/props/modal"
import componentMixin from "@/base_lib/data/mixins/componentMixin"
import SP from "@/base_lib/components/container/SP"
import Button from "@/base_lib/components/form/Button"

export default {
  name: "ConfirmModal",
  components: {SP, Modal, Button},
  props: modalProps,
  mixins: [componentMixin],
  computed: {
    computedDescription() {
      if (this.description == null) {
        return "If you click yes, this account will be deleted. This action cannot be undone."
      }
      return this.description
    }
  },
  emits: ['onClickYes', 'onClickNo'],
}
</script>

<style scoped>

</style>