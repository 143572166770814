import baseProps from "@/base_lib/data/props/base"

const tooltipButtonProps = {
    ...baseProps,
    icon: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    color: {
        type: String,
        required: false,
        default: "grey"
    },
}

export default tooltipButtonProps