<template>
  <div class="show" @mouseover="showing = true" @mouseleave="showing = false">
    <vs-sidebar v-model="active" absolute :hover-expand="autoExpand" :reduce="autoExpand" open :square="true">
      <template #logo v-if="showing || !autoExpand">
        <img :src="require(`@/assets/obocos.png`)" @click="onTitleClick">
      </template>

      <div v-bind:class="{'spacing': !showing && autoExpand }"></div>

      <div v-for="item in items">

        <!-- Single item -->
        <vs-sidebar-item v-if="item.child == null" :id="item.id" v-bind:key="item.id" :to="item.id">
          <template #icon v-if="item.image == null">
            <i :class="item.icon"></i>
          </template>
          <img v-if="item.image" :src="require(`@/assets/platform/${item.image}`)" />
          {{item.title }}
        </vs-sidebar-item>
        <!-- Single item -->

        <!-- Group item -->
        <vs-sidebar-group open v-else class="mt-4">
          <template #header>
            <vs-sidebar-item class="icon-size" arrow>
              <template #icon>
                <i v-if="item.image == null" :class="item.icon"></i>
                <img v-else :src="require(`@/assets/platform/${item.image}`)" weight="20" height="20" class="mr-1" />
              </template>
              {{ item. title }}
            </vs-sidebar-item>
          </template>

          <vs-sidebar-item v-for="child in item.child" :id="child.id" v-bind:key="child.id" :to="child.id">
            <template #icon>
              <i v-if="child.image == null" :class="child.icon"></i>
              <img v-else :src="require(`@/assets/platform/${child.image}`)" weight="20" height="20" class="mr-1" />
            </template>
            {{ child.title }}
          </vs-sidebar-item>
        </vs-sidebar-group>
        <!-- Group item -->

      </div>

      <div justify="space-between" slot="footer">
        <vs-sidebar-item v-if="isAdmin" id="admin" class="no-line" to="/admin">
          <template #icon>
            <i class="bx bx-cog"></i>
          </template>
          Admin
        </vs-sidebar-item>
        <vs-sidebar-item v-if="showDemo" id="demo" class="no-line" to="/demo">
          <template #icon>
            <i class="bx bx-play-circle"></i>
          </template>
          Demos
        </vs-sidebar-item>
        <vs-sidebar-item  id="user" class="no-line">
          <template #icon>
            <i class="bx bx-user-circle"></i>
          </template>
          <vs-tooltip>
            User: {{ fullName }}
            <template #tooltip>
              E-mail: {{ email }}
            </template>
          </vs-tooltip>
        </vs-sidebar-item>
        <vs-sidebar-item v-if="showing || !autoExpand" id="logout" class="no-line" to="/logout">
          <template #icon>
            <i class="bx bx-log-out"></i>
          </template>
          Logout
        </vs-sidebar-item>
      </div>
    </vs-sidebar>
  </div>
</template>
<script>
import {mapGetters} from "vuex"
import Button from "@/base_lib/components/form/Button"
import ossMenuItems from "@/data/menu"
import {sidebarProps} from "@/base_lib/data/props/sidebarProps"
import routeMixin from "@/base_lib/data/mixins/routeMixin"

export default {
  name: "Sidebar",
  components: {Button},
  emits: ['onChange', 'onActive'],
  mixins: [routeMixin],
  props: sidebarProps,
  computed: {
    ...mapGetters({
      fullName: "auth/fullName",
      isAdmin: "auth/isAdmin",
      email: "auth/email"
    }),
    items() {
      if (this.$props.menuItems === null) {
        return ossMenuItems
      }
      return this.menuItems
    },
    development() {
      return process.env.NODE_ENV === 'development'
    },
    showDemo() {
      return this.development && (this.showing || !this.autoExpand)
    },
  },
  data: () => ({
    showing: false,
  }),
  methods: {
    onItemClick(item) {
      console.log(item)
    },
  },
  watch: {
    showing: {
      immediate: true,
      handler(to) {
        this.$emit('onActive', to)
      }
    },
  },
}
</script>

<style>
.spacing {
  margin-top: 100px;
}
.no-line.vs-sidebar__item:after {
  background: transparent !important;
}
.vs-sidebar__item__icon {
  height: 32px !important;
}
.vs-sidebar__group__header .vs-sidebar__item__text {
  font-size: 0.9em !important;
  font-weight: 500;
}

.vs-sidebar__item__text {
  font-size: 0.8em !important;
  font-weight: 500;
}

</style>
