<template>
  <v-container v-if="text" class="pa-0 container-bottom">
    <Card :class-object="['mr-5']">
      <Sp class="text-caption font-weight-regular">{{ text }}</Sp>
    </Card>
  </v-container>
</template>

<script>
import CenterContent from "@/base_lib/components/container/CenterContent"
import ComponentMixin from "@/base_lib/data/mixins/componentMixin"
import Sp from "@/base_lib/components/container/SP"
import Card from "@/base_lib/components/container/Card"

export default {
  name: "Description",
  components: {CenterContent, Sp, Card},
  mixins: [ComponentMixin],
  props: {
    text: {
      type: String,
      required: true,
      default: null,
    },
  },
}
</script>

<style>
.container-bottom {
  position: absolute;
  bottom: 20px;
}
</style>
