import InputType from "@/base_lib/data/types/inputType"
import baseProps from "@/base_lib/data/props/base"

const inputProps = {
    ...baseProps,
    label: {
        type: String,
        required: true,
        default: "",
    },
    type: {
        type: Number,
        required: false,
        default: InputType.Other,
    },
    min: {
        type: Number,
        required: false,
        default: null,
    },
    max: {
        type: Number,
        required: false,
        default: 24,
    },
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    dense: {
        type: Boolean,
        required: false,
        default: false,
    },
    hidePasswordIcon: {
        type: Boolean,
        required: false,
        default: false,
    },
    hint: {
        type: String,
        required: false,
        default: null,
    },
    icon: {
        type: String,
        required: false,
        default: null,
    },
    noIcon: {
        type: Boolean,
        required: false,
        default: false,
    },
    disableAutocomplete: {
        type: Boolean,
        required: false,
        default: false,
    }
}

export default inputProps