import baseProps from "@/base_lib/data/props/base"

const radioProps = {
    ...baseProps,
    buttons: {
        type: Array,
        required: true,
        default: () => [],
    },
    color: {
        type: String,
        required: false,
        default: "primary",
    },
    horizontal: {
        type: Boolean,
        required: false,
        default: false,
    }
}

export default radioProps