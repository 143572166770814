<template>
<v-app id="inspire" :style="{ background: $vuetify.theme.themes[theme].background }">
  <Navbar>
    <template #custom>
      <NavigationTitle text="QR Application"></NavigationTitle>
    </template>
    <template #right-custom>
      <v-img
          contain
          @click="onTitleClick"
          class="mr-10"
          :src="require('@/assets/obocos.png')"
          width="120px"
          height="40px" />
    </template>
  </Navbar>
  <v-main id="v4-content">
    <v-container fluid>
      <CenterContent size="xs12">
        <v-card class="pa-2">
          <Alert :type="response.variant" :message="response.message" />
          <DataTable
            :title="title"
            :loading="response.loading"
            :custom-actions="actions"
            :custom-options="datatableOptions"
            :custom-header-values="dataTableHeader"
            :custom-loading-type="customLoadingType"
            :datatable-items="items">
            <template v-slot:customTitle="{ title }">
              <v-row>
                <sp class="text-h6 font-weight-bold pt-3 pl-3">{{ title }}</sp>
                <Button @onClick="onNewUserClick" title="Add an Editor" text outlined button-icon="bx bx-plus-circle" :class-div-object="['ml-10', 'mt-2']" />
              </v-row>
            </template>

            <template v-slot:item.user_id="{ item }">
              {{ item.id }}
            </template>

            <template v-slot:item.account="{ item} ">
              {{ item.first_name + " " + item.last_name }}
            </template>

            <template v-slot:item.roles="{ item }">
              {{ item.roles?.allowed_pages.length ?? 0 }} pages
            </template>

            <template v-slot:item.editor_management="{ item }">
              {{ item.is_admin }}
            </template>
          </DataTable>
        </v-card>
      </CenterContent>

      <Modal
        :title="`Edit Editor: ${editItem?.dashboard_user}`"
        :has-form="true"
        :show="editDialog"
        :cancelable="false"
        :class-object="['pa-4']"
        :width="700"
        @onClose="onEditDialogClose">
        <AdminEditModal :item="editItem" />
      </Modal>

      <Modal
        title="Add an Editor"
        :has-form="true"
        :show="addDialog"
        :cancelable="false"
        :class-object="['pa-4']"
        :width="800"
        @onClose="onAddDialogClose">
        <template v-slot="props">
          <AdminCreateModal :show="props.show" />
        </template>
      </Modal>
    </v-container>
  </v-main>
</v-app>
</template>

<script>
import Navbar from "@/base_lib/view/navigation/Navbar"
import NavigationTitle from "@/components/NavigationTitle"
import CenterContent from "@/base_lib/components/container/CenterContent"
import AppMixin from "@/base_lib/data/mixins/appMixin"
import PageMixin from "@/base_lib/data/mixins/pageMixin"
import DataTable from "@/base_lib/components/container/DataTable"
import AdminEditModal from "@/view/admin/AdminEditModal"
import Modal from "@/base_lib/components/container/Modal"
import AccountServices from "@/services/EditorServices"
import Button from "@/base_lib/components/form/Button"
import AdminCreateModal from "@/view/admin/AdminCreateModal"
import Alert from "@/base_lib/components/view/Alert"
import routeMixin from "@/base_lib/data/mixins/routeMixin"

export default {
  name: "AdminHome",
  components: {Alert, AdminCreateModal, Button, Modal, AdminEditModal, DataTable, CenterContent, Navbar, NavigationTitle},
  mixins: [AppMixin, PageMixin, routeMixin],
  computed: {
    actions() {
      return [
        {
          title: "Edit",
          click: this.onUpdateClick
        },
      ]
    },
    customLoadingType() {
      return "table-thead, table-tbody, table-tfoot"
    }
  },
  data: () => ({
    title: 'Editor List',
    items: [],
    datatableOptions: {
      page: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [5, 20, 30, 50, 100],
    },
    dataTableHeader: [
      'id', 'account', 'dashboard_editor', 'editor_status', 'editor_management', 'roles', 'action',
    ],

    // Edit
    editDialog: false,
    editItem: null,

    // Add
    addDialog: false,
  }),
  methods: {
    listUsers() {
      this.response.loading = true
      AccountServices.list().then(response => {
        this.items = response.data.data.list.map(item => ({
          ...item,
          dashboard_editor: item.dashboard_user,
        }))
        this.response = response.networkResponse(true)
      }).catch(error => {
        if (error.networkError !== undefined) {
          this.response = error.networkError(error)
        }
      }).finally(() => {
        this.response.loading = false
      })
    },

    // Edit dialog
    onUpdateClick(item) {
      this.editItem = item
      this.editDialog = true
    },
    onEditDialogClose() {
      this.editDialog = false
      this.listUsers()
    },

    // New User dialog
    onNewUserClick() {
      this.addDialog = true
    },
    onAddDialogClose() {
      this.addDialog = false
      this.listUsers()
    },
  },
  mounted() {
    this.listUsers()
  }
}
</script>

<style scoped>
#v4-content {
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 40px;
}
</style>
