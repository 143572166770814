<template>
  <CenterContent v-else size="xs12">
    <SP :class="['mb-3', 'text-subtitle-1', 'font-weight-light']">{{ viewHelpers.description }}</SP>
    <v-card class="pa-2" outlined>
      <TextBox
          :value="accounts"
          :label="viewHelpers.textBoxLabel"
          :hint="viewHelpers.textBoxHint"
          :error="textBoxError"
          :disabled="alert.loading"
          @input="onAccountChange" />
      <v-row class="mt-n8">
        <v-col cols="2" class="ma-0">
          <Checkbox
              title="Enable filter"
              :value="enableFilter"
              @input="(value) => { enableFilter = value }" />
        </v-col>
        <v-col cols="6">
          <Input
              label="Please put commas between each keyword"
              v-model="filter"
              :class-object="['mt-n1']"
              :disabled="!enableFilter" />
        </v-col>
      </v-row>
      <Alert loading :type="alert.variant" :message="alert.message" />
      <Alert outlined type="error" border="left" :message="warningError" />
      <Alert outlined :type="response.variant" border="left" :message="response.message" />
      <Alert type="success" :message="successMessage">
        <Button title="Download" outlined small block @onClick="downloadFile"></Button>
      </Alert>
      <v-row class="my-0">
        <v-col cols="6">
          <Button
              title="Scrape Invoice(s)"
              :disabled="alert.loading"
              :class-object="['px-10']"
              @onClick="onButtonClick"
              prepend-icon="bx bx-refresh"
              outlined />
        </v-col>
        <v-col cols="6">
          <Button
              title="Show My History"
              :disabled="alert.loading"
              :class-object="['px-10', 'float-right']"
              @onClick="onHistoryClick"
              prepend-icon="bx bx-history"
              outlined />
        </v-col>
      </v-row>
    </v-card>
    <BottomSheet
        v-model="historySheet"
        class="mb-10"
        @onClose="onHistoryClose">
      <Alert type="error" :message="history.message" />
      <DataTable
        title="History"
        custom-loading-type="table-thead, table-tbody, table-tfoot"
        filterable
        group-by="status"
        :custom-actions="customActions"
        :loading="history.loading"
        :custom-options="datatableOptions"
        :custom-header-values="historyHeaderValues"
        :datatable-items="historyItems">
        <template v-slot:item.accounts="{ item }">
          <TooltipLabel v-if="item.accounts_tooltip && item.accounts_tooltip !== ''" top :label="item.accounts_tooltip">
            {{ item.accounts }}
          </TooltipLabel>
          <span v-else>{{ item.accounts }}</span>
        </template>
      </DataTable>
    </BottomSheet>
  </CenterContent>
</template>

<script>
import SP from "@/base_lib/components/container/SP.vue"
import CenterContent from "@/base_lib/components/container/CenterContent.vue"
import TextBox from "@/base_lib/components/form/TextBox.vue"
import Button from "@/base_lib/components/form/Button.vue"
import TicketList from "@/view/other/TicketList.vue"
import {baseValidation} from "@/data/view/validate"
import helper from "@/utils/viewHelper"
import PageMixin from "@/base_lib/data/mixins/pageMixin"
import ScrapeService from "@/services/ScrapeService";
import accountModalStructure from "@/data/view/accountStructure"
import RequestService from "@/services/RequestService"
import Alert from "@/base_lib/components/view/Alert.vue"
import BottomSheet from "@/base_lib/components/container/BottomSheet.vue"
import DataTable from "@/base_lib/components/container/DataTable.vue";
import TooltipLabel from "@/base_lib/components/container/TooltipLabel.vue"
import Input from "@/base_lib/components/form/Input.vue";
import Checkbox from "@/base_lib/components/form/Checkbox.vue"
import GenerateService from "@/services/GenerateServices"
import socketMixin from "@/data/mixins/socketMixin"
import pageProjectMixin from "@/data/mixins/pageProjectMixin"

export default {
  name: "credits",
  components: {
    Checkbox, Input, TooltipLabel,
    DataTable, BottomSheet, Alert,
    TicketList, Button, TextBox, CenterContent, SP
  },
  mixins: [PageMixin, socketMixin, pageProjectMixin],
  computed: {
    customActions() {
      return [
        {
          title: "Download",
          click: this.onHistoryItemClick,
          isRendered: (item) => {
            return item.status === 'completed'
          }
        },
        {
          title: "Retry",
          click: this.onHistoryItemRetryClick,
          isRendered: (item) => {
            return item.status === 'failed'
          }
        },
      ]
    },
  },
  data: () => ({
    accounts: null,

    // TextBox variables
    warningError: null,
    textBoxError: "",
    textBoxErrorLines: [],
    enableFilter: false,
    filter: null,

    // History variables
    historySheet: false,
    historyItems: [],
    historyHeaderValues: [],
    historyErrorMessage: null,
    datatableOptions: {
      page: 1,
      itemsPerPage: 5,
      itemsPerPageOptions: [5],
    },

    // Ticket items
    fileBuffer: null,
    successMessage: null,
  }),
  methods: {
    onButtonClick() {
      this.resetHistoryId()
      this.warningError = null
      this.response.message = null
      this.textBoxError = ""
      this.textBoxErrorLines = []
      this.ticketItems = null
      this.successMessage = null

      let validateResponse = baseValidation(this.accounts, this.viewHelpers.rules)
      if (validateResponse.success === false) {
        this.textBoxError = validateResponse.message
        this.warningError = validateResponse.other.map(line => line.message).join("<br/>")
        return
      }

      this.warningError = validateResponse.other.map(line => line.message).join("<br/>")

      let accounts = accountModalStructure(this.$route.meta.type, validateResponse.validLines)
      let data = {
        accounts: accounts,
        filter_keywords: this.enableFilter ? this.filter : "",
        custom_data: {
          ...this.custom_data,
          type: "INVOICE",
        },
      }

      this.alert.loading = true
      this.alert.message = "Please stand by"
      this.response.loading = true
      this.scrollToTop()

      ScrapeService.invoice(data).then(response => {
        if (response.data.data.request_status === "requested") {
          this.socketRequestId = response.data.data.request_id
          this.alert.message = "We got your request. Scraping credits... \nYou can close this page and check it later from history. (" + response.data.data.request_id + ")"
        } else {
          this.warningError = "An error occurred. Please try again"
          this.alert.message = null
          this.alert.loading = false
        }
        this.scrollToTop()
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
        this.alert.message = null
        this.alert.loading = false
      }).finally(() => {
        this.response.loading = false
      })
    },
    onAccountChange(accounts) {
      this.accounts = accounts
    },
    onHistoryClick() {
      this.history.loading = true
      this.historySheet = true

      RequestService.history(this.viewHelpers.filter).then(response => {
        this.history = response.networkResponse(true)
        if (response.data.data.list.length > 0) {
          this.historyItems = this.decorateHistoryData(response.data.data.list)
          this.historyHeaderValues = Object.keys(this.historyItems[0]).filter(item => this.historyHeaderIgnore.includes(item) === false)
        }
      }).catch(error => {
        if (error.networkError === 'function') {
          this.history = error.networkError?.(error)
        } else {
          this.history = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.response.loading = false
      })
    },
    decorateHistoryData(items) {
      return items.map(item => ({
        request_id: item.request_uuid,
        editor: [item.editor.first_name, item.editor.last_name].join(" "),
        editor_object: item.editor,
        accounts: `${item.request_data.accounts.length} accounts`,
        accounts_tooltip: item.request_data.accounts.map(account => `${account.account_team}:${account.account_user}`).join("<br/>"),
        result: item.api_response_message,
        status: item.request_status,
        request_time: this.printableDate(item.created_at),
        action: "",
        _search: JSON.stringify(item).toLowerCase(),
        _item: item,
      }))
    },
    getTicketList(id) {
      RequestService.historyDetail(id).then(response => {
        if (this.alert.loading === false) {
          return
        }
        let data = response.data.data.decorated_response

        if (Array.isArray(data) && data.length > 0) {
          this.successMessage = `Success: ${response.data.data.api_response.transaction_response._response_message}`
        } else if (response.data.code === 100) {
          this.response.message = this.getDetailedError(response)
        } else {
          this.response.message = "An error occurred. Please try again."
        }

        this.alert.message = null
        this.alert.loading = false
        this.scrollToTop()
      }).catch(error => {
        if (error.networkError === 'function') {
          this.response = error.networkError?.(error)
        } else {
          this.response = this.returnCustomError(error.toString())
        }
      }).finally(() => {
        this.alert.message = null
        this.alert.loading = false
      })
    },
    onHistoryClose() {
      this.historySheet = false
      this.historyItems = []
    },
    onHistoryItemClick(item) {
      this.fileBuffer = item._item.request_uuid
      this.downloadFile()
    },
    onHistoryItemRetryClick(item) {
      this.checkParams(item._item)
      this.onHistoryClose()
    },
    downloadFile() {
      this.alert.loading = true
      this.alert.message = "Please stand by"

      GenerateService.download(this.fileBuffer, "REFRESH_INVOICE").then(response => {
        let reader = new FileReader()
        reader.onload = () => {
          let blob = new Blob([response.data], { type: 'application/zip' })
          let url = window['URL'].createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = this.fileBuffer
          a.click()
        }
        reader.readAsBinaryString(response.data)
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.alert.loading = false
        this.alert.message = null
      })
    },
    onSocketData(data) {
      this.alert.message = null
      this.alert.loading = false

      if (data.status === 'completed') {
        this.successMessage = `Success: ${data.response_message}`
        this.fileBuffer = data.request_id
      } else {
        this.response.variant = 'error'
        this.response.message = data.response_message
        if (data.response_errors?.length > 0) {
          let errorMessages = data.response_errors.map(err => `${err.account_user} -> ${err._error_message}`).join("<br />")
          this.response.message += "<br/>" + errorMessages
        }
      }
    },
    checkParams(item) {
      if (this.$route.query.accounts) {
        this.applyQueryParams({
          accounts: this.$route.query.accounts,
          enableFilter: this.$route.query.enableFilter,
          filter: this.$route.query.filter,
        })
      } else if (item) {
        this.applyQueryParams({
          accounts: item.request_data.accounts.map(acc => [acc.account_team, acc.account_user, acc.account_password].join(":")).join("\n"),
          enableFilter: item.request_data.filter !== "",
          filter: item.request_data.filter,
        })
      }
    },
    applyQueryParams({accounts, enableFilter, filter}) {
      this.accounts = accounts
      this.enableFilter = enableFilter
      this.filter = filter
    }
  },
  created() {
    this.hasSocket = true
    this.viewHelpers = helper(this.$route.meta.type)
    this.accounts = this.viewHelpers.textBoxModel
  },
  mounted() {
    this.checkParams()
  }
}
</script>