<template>
<v-app id="inspire" :style="{ background: $vuetify.theme.themes[theme].background }">
  <Sidebar :platform="loginPlatform" :menu-items="items" :auto-expand="false"></Sidebar>
  <Navbar>
    <template #right-custom="{ active }">
      <NavigationTitle text="QR Application"></NavigationTitle>
      <Button
        outlined
        title="Raise an Issue"
        prepend-icon="bx bx-plus"
        @onClick="onCreateIssueClick"
        :class-div-object="['ml-4']" />
    </template>
  </Navbar>
  <v-main id="v4-content">
    <v-container fluid>
      <router-view :key="$route.fullPath"></router-view>
    </v-container>
  </v-main>
</v-app>
</template>

<script>
import Vue from "vue"
import {mapGetters} from "vuex"
import AppMixin from "@/base_lib/data/mixins/appMixin"
import PageMixin from "@/base_lib/data/mixins/pageMixin"
import Sidebar from "@/base_lib/view/navigation/Sidebar"
import Navbar from "@/base_lib/view/navigation/Navbar"
import NavigationTitle from "@/components/NavigationTitle"
import Button from "@/base_lib/components/form/Button"

export default {
  name: "Home",
  components: {NavigationTitle, Navbar, Sidebar, Button},
  mixins: [AppMixin, PageMixin],
  data: () => ({
    items: [],
  }),
  computed: {
    ...mapGetters({
      loginPlatform: 'auth/platform',
      fullName: "auth/fullName",
      isAdmin: "auth/isAdmin",
    }),
  },
  methods: {
    onCreateIssueClick() {
      Vue.prototype.$showCollectorDialog()
    },
  },
  mounted() {
    this.items = this.$store.getters['roles/page'].items
  },
}
</script>

<style scoped>
#v4-content {
  margin-top: 15px;
  margin-left: 260px;
  margin-right: 0px;
}
</style>
