<template>
  <div v-bind:class="computedDivClass">
    <v-row>
      <v-col :cols="col" :offset="offset">
        <slot>No content</slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import singleRowProps from "@/base_lib/data/props/singleRowProps"
import ComponentMixin from "@/base_lib/data/mixins/componentMixin"

export default {
  name: "SingleRow",
  components: {},
  mixins: [ComponentMixin],
  props: singleRowProps,
  computed: {
    offset() {
      if (this.center) {
        return Math.round((12 - this.col) / 2)
      }

      return 0
    },
  },
}
</script>

<style scoped>

</style>
