<template>
  <CenterContent size="xs12 sm10 md4 lg4 xl4">
    <Panel :form="form" ref="panel" title="Forgot Password" description="Enter your e-mail to get instructions" @onFormChange="onFormChange" :class-object="['px-5']">
      <Input dense outlined label="E-mail" :type="emailType" v-model="form.email" />
      <Alert :type="response.variant" :message="response.message" />
      <Button @onClick="onButtonClick" :loading="this.response.loading" title="Submit" :class-object="['primary']" block text large></Button>
      <SP @onClick="onHaveAccountClick" isButton class="mt-5 text-center font-weight-light caption" block text small>Have Account?</SP>
    </Panel>
  </CenterContent>
</template>

<script>
import Button from "@/base_lib/components/form/Button"
import Alert from "@/base_lib/components/view/Alert"
import Input from "@/base_lib/components/form/Input"
import Panel from "@/base_lib/components/container/Panel"
import CenterContent from "@/base_lib/components/container/CenterContent"
import pageMixin from "@/base_lib/data/mixins/pageMixin"
import AccountServices from "@/services/EditorServices"
import router from "@/router"
import SP from "@/base_lib/components/container/SP"

export default {
  name: "Forgot.vue",
  components: {SP, Button, Alert, Input, Panel, CenterContent},
  mixins: [pageMixin],
  data: () => ({
    form: {
      email: null,
    }
  }),
  methods: {
    onButtonClick() {
      this.$refs.panel.validate()

      if (this.isFormValid) {
        this.response.loading = true
        AccountServices.forget(this.form.email).then(response => {
          this.$refs.panel.reset()
          this.response = response.networkResponse()
        }).catch(error => {
          if (error.networkError === 'function') {
            this.response = error.networkError?.(error)
          } else {
            this.response = this.returnCustomError(error.toString())
          }
        })
      }
    },
    onHaveAccountClick() {
      router.push({ name: 'login' })
    }
  },
}
</script>

<style scoped>

</style>
