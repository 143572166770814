<template>
  <v-menu offset-y transition="slide-y-transition" class="mt-3" v-bind:class="computedClass">
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-large icon v-bind="attrs" v-on="on">
        <v-icon color="primary" x-large>bx bxs-grid</v-icon>
      </v-btn>
    </template>
    <v-card class="mx-auto px-3" max-width="300" tile>
      <v-list rounded>
        <v-subheader class="font-weight-bold">Switch To</v-subheader>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="item in items" :key="item.id">
            <v-list-item-icon class="mr-2">
              <v-img max-height="32" max-width="32" class="rounded-lg" :src="require('@/assets/platform/'+getImage(item))" />
            </v-list-item-icon>
            <v-list-item-content>
              <sp class="font-weight-thin text-subtitle-2">{{ item.platform_name }}</sp>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import Vue from "vue"
import componentMixin from "@/base_lib/data/mixins/componentMixin"
import Sp from "@/base_lib/components/container/SP"
import Button from "@/base_lib/components/form/Button"
import pickerProps from "@/base_lib/data/props/pickerProps"

export default {
  name: "Picker",
  components: {Sp, Button},
  mixins: [componentMixin],
  emits: ['onPlatformChange'],
  props: pickerProps,
  data: () => ({
    selectedItem: 0,
  }),
  methods: {
    getImage(item) {
      return item.slug.toString() + ".png"
    }
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(to) {
        if (this.items !== null && this.items.length > 0 && this.items[to].id != null) {
          let platform = this.items[to]
          this.$emit('onPlatformChange', platform)
          Vue.prototype.$stateManagement.dispatch('auth/changePlatform', platform)
        }
      }
    }
  }
}
</script>