<template>
  <DemoBase size="xs6">
    <Panel :form="form" ref="panel" title="Welcome" description="Sign in your account" @onFormChange="onFormChange" :class-object="['px-5']">
      <Input outlined label="E-mail"/>
      <Input outlined label="Password" />
      <Button title="Panel Demo Button" :class-object="['primary']" block text large></Button>
    </Panel>
  </DemoBase>
</template>

<script>
import DemoBase from "@/base_lib/demo/base/DemoBase"
import PageMixin from "@/base_lib/data/mixins/pageMixin"
import Panel from "@/base_lib/components/container/Panel"
import Input from "@/base_lib/components/form/Input"
import Button from "@/base_lib/components/form/Button"

export default {
  name: "PanelDemo",
  components: {DemoBase, Panel, Input, Button},
  mixins: [PageMixin],
  data: () => ({
    form: {
      username: null,
      password: null,
    },
  }),
}
</script>

<style scoped>

</style>