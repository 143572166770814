import types from "@/data/types"
import tableSort from "@/data/view/tableSort"
import {fieldSorter} from "@/base_lib/helper/utils"

const prepareCSVItems = (type, items) => {
    let sortFields = tableSort(type)

    switch (type) {
        case types.OSS_API:
            return items.map(item => ({
                team: item.team,
                account: item.account_user,
                event_id: item.event_id,
                event: item.event,
                alternative: item.alternative,
                year: item._item?.event_detail?.__event_date_year === '2999' ? 'TBD' : item._item?.event_detail?.__event_date_year,
                date: item._item.event_detail.__event_date_us_format,
                time: item._item.event_detail.__event_time_us_format,
                venue: item.venue,
                section: item.section,
                row: item.row,
                seat: item.seat,
                qr: item.barcode,
                additional: item.additional,
            }))
        case types.SG:
            return items.map(item => ({
                account: item.account_user_tooltip,
                date: item._item?.event_detail?.__event_date_us_format,
                time: item._item?.event_detail?.__event_time_us_format,
                section: item.section,
                row: item.row,
                seat: item.seat,
                event: item.event,
                event_id: item.event_id,
                team: item.team,
                team_id: item._item?.account_id,
                year: item.year,
                venue: item.venue,
                qr: item.qr,
            }))
        case types.BALLPARK_APP:
            return items.map(bItem => ({
                ...bItem,
                barcode_ext: (bItem.barcode.startsWith("[")) ? "" : bItem.barcode + ":1T:" + bItem.ticket_id?.split("@")[0],
            })).map( ({ _search, _item, id, designated_id, timestamp, ...restOfItems}) => restOfItems)
            .sort(fieldSorter((sortFields)))
        default:
            return items.map( ({ _search, _item, id, designated_id, timestamp, ...restOfItems}) => restOfItems)
    }
}

export default prepareCSVItems