import baseProps from "@/base_lib/data/props/base"

const loadingCardProps = {
    ...baseProps,
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    type: {
        type: String,
        required: false,
        default: 'card',
    }
}

export default loadingCardProps
