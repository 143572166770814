<template>
  <Modal
    persistent
    :cancelable="cancelable"
    :has-form="false"
    :show="show"
    :overlay="true"
    :class-object="['pa-4']"
    @onClose="onClose">
    <v-card-text class="ma-0 pa-0">
      {{ description }}
      <v-progress-linear
        indeterminate
        color="primary"
        class="my-1"
    ></v-progress-linear>
    </v-card-text>
  </Modal>
</template>

<script>
import componentMixin from "@/base_lib/data/mixins/componentMixin"
import modalProps from "@/base_lib/data/props/modal"
import Modal from "@/base_lib/components/container/Modal"

export default {
  name: "LoadingModal",
  components: {Modal},
  props: modalProps,
  mixins: [componentMixin],
  methods: {
    onClose() {
      this.$emit('onClose')
    },
  }
}
</script>

<style scoped>

</style>
