import Vue from "vue"

const path = "pk-pass"

export default {
    generateSocket(file, nextevent, custom_data, callback) {
        const formData = new FormData()
        formData.append("file", file)
        formData.append("nextevent", nextevent);
        formData.append("custom_data", JSON.stringify(custom_data))

        const config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                callback?.(percentCompleted)
            },
            timeout: 1000000,
        }

        return Vue.prototype.$http.post(`${path}/pdf-with-queue`, formData, config)
    },
    generate(file, custom_data, callback) {
        const formData = new FormData()
        formData.append("file", file)
        formData.append("custom_data", JSON.stringify(custom_data))

        const config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                callback?.(percentCompleted)
                console.log(percentCompleted)
            },
            timeout: 1000000,
        }

        return Vue.prototype.$http.post(`${path}/pdf`, formData, config)
    },
    history() {
        return Vue.prototype.$http.get(`${path}/pdf/list`)
    }
}
