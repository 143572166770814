import Vue from 'vue'
import {defaultPlatform} from "@/base_lib/data/default/state"

export default {
    namespaced: true,
    state() {
        return {
            token: null,
            user: null,
            account: null,
            isLoggedIn: null,
            platform: defaultPlatform,
        }
    },
    getters: {
        token: function (state) {
            return state.token
        },
        isLoggedIn: function (state) {
            return state.isLoggedIn
        },
        platform: function (state) {
            return state.platform
        },
        fullName: function(state) {
            return state.account?.first_name + " " + state.account?.last_name
        },
        email: function (state) {
            return state.account?.dashboard_user ?? ""
        },
        isAdmin: function (state) {
            return state.account?.is_admin ?? false
        },
        account: function(state) {
            return state.account
        },
        editorId: function (state) {
            return state.account?.editor_id
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
            state.isLoggedIn = !!token
        },
        SET_USER(state, user) {
            state.user = user
        },
        SET_CUSTOMER(state, customer) {
            state.customer = customer
        },
        SET_PLATFORM(state, platform) {
            state.platform = platform
        },
        SET_ACCOUNT(state, account) {
            state.account = account
        },
    },
    actions: {
        logout({commit}) {
            // Remove cookies
            Vue.$cookies.remove('user')
            Vue.$cookies.remove('account')
            Vue.$cookies.remove('customer')
            Vue.$cookies.remove('token')

            // Commit actions
            commit('SET_TOKEN', null)
            commit('SET_USER', null)
            commit('SET_ACCOUNT', null)
            commit('SET_CUSTOMER', null)
        },
        login({commit}, {user, account, customer, token}) {
            // Add cookies
            Vue.$cookies.set('user', user)
            Vue.$cookies.set('account', account)
            Vue.$cookies.set('customer', customer)
            Vue.$cookies.set('token', token)

            // Commit actions
            commit('SET_TOKEN', token)
            commit('SET_USER', user)
            commit('SET_ACCOUNT', account)
            commit('SET_CUSTOMER', customer)
        },
        changePlatform({commit}, platform) {
            Vue.$cookies.set('platform', platform)
            commit('SET_PLATFORM', platform)
        },
    }
}
