import baseProps from "@/base_lib/data/props/base"

const singleRowProps = {
    ...baseProps,
    col: {
        type: Number,
        required: true,
    },
    center: {
        type: Boolean,
        required: false,
        default: false,
    },
}

export default singleRowProps
