import {camelToTitle, getHeaderValues} from "@/base_lib/helper/utils"
import {headerGroupByValues} from "@/base_lib/data/matches"

const dataTableMixin = {
    data: () => ({
        query: null,
        items: [],
        headers: [],
        selectedRow: [],
        selectedHeader: [],
        groupingItems: [],
        selectedGroupBy: null,
        filteredHeader: [],
        expandedSection: [],
        headerFilterItems: [],
        // Options
        options: {
            count: 0,
            page: 1,
            itemsPerPage: 100,
            itemsPerPageOptions: [50, 100, 150],
            sort: null,
        },

        // Refresh key
        refreshItemKey: 0,
    }),
    computed: {
        computedTitle: {
            get: function() {
                if (this.$slots.customTitle) {
                    return this.$props.title
                } else if (this.$props.selectable && this.$props.customComputedSelectableTitle != null) {
                    let ticketCount = this.selectedRow.length === this.computedItems.length ? this.selectedRow.length : `${this.selectedRow.length}/${this.computedItems.length}`
                    let selectedTickets = `(${ticketCount} tickets)`
                    return this.$props.customComputedSelectableTitle + " " + selectedTickets
                } else {
                    return this.$props.title
                }
            },
        },
        computedItems: {
            get: function() {
                this.refreshItemKey;

                let items = this.$props.datatableItems.map((item, index) => ({
                    ...item,
                    designated_id: index+1,
                }))

                if (this.groupBy != null && this.selectedGroupBy != null && Object.keys(items[0]).indexOf(this.groupBy) > 0) {
                    items = items.filter(item => item[this.groupBy] === this.selectedGroupBy)
                }

                if (this.headerGroupBy) {
                    if (this.groupBy != null && this.selectedGroupBy != null && Object.keys(items[0]).indexOf(this.groupBy) > 0) {
                        console.error("Please check groupBy for this DataTable.")
                        return items
                    }

                    Object.keys(this.headerFilterItems).forEach(key => {
                        if (this.headerFilterItems[key] != null) {
                            items = items.filter(item => this.headerFilterItems[key].includes(item[key]))
                        }
                    })
                }

                return items
            }
        },
        dataTableOptions: {
            get: function () {
                if (this.customOptions) {
                    return this.customOptions
                } else {
                    return this.options
                }
            },
            set: function (newValue) {
                this.options = newValue
            },
        },
        dataTableHeader() {
            return (items) => {
                if (items != null && items[0] != null) {
                    let item = items[0]
                    return getHeaderValues(item, this.customHeaderIgnore, this.customHeaderValues)
                }
                return []
            }
        },
        itemKey() {
            return "id"
        },
        computedGroupByTitle() {
            return "Group By " + camelToTitle(this.groupBy)
        }
    },
    methods: {
        getBigTableSkeleton() {
            return "table-heading, table-thead, table-tbody, table-tbody, table-tbody, table-tfoot"
        },
        getTableSkeleton() {
            return "table-heading, table-thead, table-tbody, table-tfoot"
        },
        onSelectChange(item) {
            this.selectedHeader = this.headers.filter(header => item.includes(header.value))
        },
        onExpandClick(item) {
            if (this.expandedSection.includes(item)) {
                this.expandedSection = []
                return
            }
            this.expandedSection = []
            this.expandedSection.push(item)
        },

        // Dynamic slots handling
        getVal(item, path) {
            return path.split(".").reduce((res, prop) => res[prop], item)
        },
        getHeader(header) {
            return camelToTitle(header)
        },
        getHeaderValueItems(header) {
            return [... new Set(this.datatableItems.map(item => item[header]))]
        },
        onHeaderGroupByChange(item) {
            this.refreshItemKey++;
        },

        // Group by handling
        setGroupByItems() {
            this.groupingItems = this.datatableItems.map(item => item[this.groupBy]).filter( (item, index, array) => array.indexOf(item) === index)
        },
        onGroupSelected(item) {
            this.selectedGroupBy = item
            this.refreshItemKey++;
        },
        onSelectAll(event) {
            if (this.selectedRow.length === this.computedItems.length) {
                this.selectedRow = []
            } else {
                this.selectedRow = this.computedItems
            }
        },

        // Other helpers
        hasGroupByFunction(header) {
            return headerGroupByValues.includes(header)
        }
    },
    watch: {
        datatableItems: {
            immediate: true,
            handler(to) {
                this.headers = this.dataTableHeader(to)
                this.selectedHeader = this.headers
                this.filteredHeader = this.headers

                if (this.selectAll) {
                    this.selectedRow = to
                }

                if (this.groupBy !== null) {
                    this.setGroupByItems()
                }

                /// Select all items as default on header filter menu
                if (this.headerGroupBy) {
                    let headerValues = this.dataTableHeader(to).map(item => item.value)
                    headerValues.forEach(key => {
                        this.headerFilterItems[key] = this.getHeaderValueItems(key)
                    })
                }
            },
        },
        selectedRow: {
            immediate: false,
            handler(to) {
              this.$emit('onSelect', to)
            },
        }
    }
}

export default dataTableMixin
